import './home.css';
import { useEffect, useState } from 'react';
import { db, auth, functions } from '../FirebaseAuthConfig.js';
import { doc, onSnapshot } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';

import SRC_profileImg from '../assets/Icons/person.png';
import SRC_closeImg from '../assets/Icons/close.png';
import ProfileBlock from '../Blocks/profileBlock.js'
import HeaderBlock from '../Blocks/headerBlock.js';
import TopRowBlock from '../Blocks/topRowBlock.js';
import SecondRowBlock from '../Blocks/secondRowBlock.js';
import MedalBlock from '../Blocks/medalBlock.js';
import ActivityBlock from '../Blocks/activityBlock.js';
import AnnouncementBlock from '../Blocks/announcementBlock.js';
import TeamBlock from '../Blocks/teamBlock.js';
import IndividualBlock from '../Blocks/individualBlock.js';

import Loader from './loader.js';

// import ConfettiGenerator from 'confetti-js';

let currentDate = new Date().toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"})

function Home() {
    // const mobile = navigator.userAgentData.mobile
    // if (mobile === true) {
    //     console.log("Mobile")
    // }

    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     // true for mobile device
    //     console.log("mobile device");
    // } else {
    //     // false for not mobile device
    //     console.log("not mobile device");
    // }

    const [currentAuthUser, setCurrentAuthUser] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [profileSelected, setProfileSelected] = useState(false);

    useEffect(() => {
        let unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                setCurrentAuthUser(currentUser);

                const checkUser = httpsCallable(functions, 'checkForUser');
                checkUser()
                .then((response) => {
                var resultData = response.data;

                if (resultData.result === 'userExists - ISregistered') {
                    return;
                } else {
                    auth.signOut();
                }
                });
            } else {
                console.log('No logged in user found. Redirecting.');

                window.location.replace('/login');
            }
        });
        return () => {
            unsubscribe()
        }
    }, []);

    useEffect(() => {
        // console.log("user changed ", currentAuthUser);
        if (!currentAuthUser) {
            return;
        }

        // console.log("here")
        const getUserData = onSnapshot(doc(db, "userCollection", currentAuthUser.uid), (snapshot) => {
            console.log("getting data")
            setData(snapshot.data());
            setIsLoading(false);
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        return () => {
            getUserData();
        };
    }, [currentAuthUser]);

    // useEffect(() => {
    //     var confettiSettings = {}

    //     if (window.innerWidth >= 640) {
    //         confettiSettings = {
    //             target: 'confetti-canvas',
    //             max: 90,
    //             size: 1.25,
    //             animate: true,
    //             respawn: true,
    //             clock: 20,
    //             rotate: true,
    //         };
    //     } else if (window.innerWidth <= 639) {
    //         confettiSettings = {
    //             target: 'confetti-canvas',
    //             max: 45,
    //             size: 0.75,
    //             animate: true,
    //             respawn: true,
    //             clock: 15,
    //             rotate: true,
    //         };
    //     }

    //     const confetti = new ConfettiGenerator(confettiSettings);
    //     confetti.render();

    //     return () => confetti.clear();
    // }, []);

  return(
    <div className="Home">
        <canvas className="confetti-canvas" id="confetti-canvas"></canvas>
        <div className='mainDiv'>
            <div className='header'>
                <div className='spacer' />
                <a className='logo' href='/' />
                {profileSelected === true?
                    <button className='profile' onClick={() => setProfileSelected(false)}>
                        <img className='profileImg' src={SRC_closeImg} alt='profileImg' />
                    </button>
                    :
                    null
                }
                {profileSelected === false?
                    <button className='profile' onClick={() => setProfileSelected(true)}>
                        {data.profilePic !== ''?
                            <img className='profileImg2' referrerPolicy='no-referrer' src={data.profilePic} alt='profileImg' />
                            :
                            null
                        }
                        {data.profilePic === ''?
                            <img className='profileImg' src={SRC_profileImg} alt='profileImg' />
                            :
                            null
                        }
                    </button>
                    :
                    null
                }
            </div>
            <div className='column'>
                {profileSelected === true?
                    <div className='content'>
                        <ProfileBlock />
                    </div>
                    :
                    null
                }
                {profileSelected === false?
                    <>
                        <div className='helloDate'>
                            <h3 className='date'>{currentDate}</h3>
                            <div className='horizontalDiv'>
                                <h1 className='hello'>Hi, {data.firstName}</h1>
                            </div>
                        </div>
                        <div className='content'>
                            <HeaderBlock />
                            <TopRowBlock />
                            {/* <h1 className='rulesText'><i>*Select your short sleeve hoodie size (or gift card option) on your profile for prizing.*</i></h1> */}
                            <MedalBlock />
                            <h1 className='rulesText'><i>*Medals can take up to 48 hours to be distributed.*</i></h1>
                            <SecondRowBlock />
                            <ActivityBlock />
                            {/* <TeamBlock /> */}
                            {/* <IndividualBlock /> */}
                        </div>
                    </>
                    :
                    null
                }
            </div>
        </div>
        {isLoading === true?
            <Loader />
            :
            null
        }
    </div>
  );
};

export default Home;