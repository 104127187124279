import './test.css';
import { useEffect, useState } from 'react';
import { db, auth } from '../FirebaseAuthConfig.js';
import { doc, onSnapshot, getDoc } from 'firebase/firestore';

import SRC_profileImg from '../assets/Icons/person.png';
import SRC_closeImg from '../assets/Icons/close.png';
import ProfileBlock from '../Blocks/profileBlock.js'
import HeaderBlock from '../Blocks/headerBlock.js';
import MedalBlock from '../Blocks/medalBlock.js';
import TeamBlock from '../Blocks/teamBlock.js';
import IndividualBlock from '../Blocks/individualBlock.js';

import Loader from './loader.js';

let currentDate = new Date().toLocaleDateString('en-us', { weekday:"long", month:"long", day:"numeric"})

function Test() {
    // const mobile = navigator.userAgentData.mobile
    // if (mobile === true) {
    //     console.log("Mobile")
    // }

    // if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
    //     // true for mobile device
    //     console.log("mobile device");
    // } else {
    //     // false for not mobile device
    //     console.log("not mobile device");
    // }

    const [adminState, setAdminState] = useState(null);
    const [currentAuthUser, setCurrentAuthUser] = useState(null);
    const [data, setData] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [profileSelected, setProfileSelected] = useState(false);

    useEffect(() => {
        let unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                setCurrentAuthUser(currentUser);
                await getDoc(doc(db, `userCollection/${currentUser.uid}/challengeData/immeasurablySore`))
                .then((response) => {
                    if (response.data().isAdmin === true) {
                        setAdminState(true);
                    } else {
                        return window.location.replace('/login');
                    }
                });
            } else {
                console.log('No logged in user found. Redirecting.');

                window.location.replace('/login');
            }
        });
        return () => {
            unsubscribe()
        }
    }, []);

    useEffect( () => {
        // console.log("user changed ", currentAuthUser);
        if (!currentAuthUser) {
            return;
        }

        // console.log("here")
        const getUserData = onSnapshot(doc(db, "userCollection", currentAuthUser.uid), (snapshot) => {
            console.log("getting data")
            setData(snapshot.data());
            setIsLoading(false);
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        return () => {
            getUserData();
        };
    }, [currentAuthUser, adminState]);

  return(
    <div className="Test">
        <div className='mainDiv'>
            <div className='header'>
                <div className='spacer' />
                <a className='logo' href='/home' />
                {profileSelected === true?
                    <button className='profile' onClick={() => setProfileSelected(false)}>
                        <img className='profileImg' src={SRC_closeImg} alt='profileImg' />
                    </button>
                    :
                    null
                }
                {profileSelected === false?
                    <button className='profile' onClick={() => setProfileSelected(true)}>
                        {data.profilePic !== ''?
                            <img className='profileImg2' referrerPolicy='no-referrer' src={data.profilePic} alt='profileImg' />
                            :
                            null
                        }
                        {data.profilePic === ''?
                            <img className='profileImg' src={SRC_profileImg} alt='profileImg' />
                            :
                            null
                        }
                    </button>
                    :
                    null
                }
            </div>
            <div className='column'>
                {profileSelected === true?
                    <div className='content'>
                        <ProfileBlock />
                    </div>
                    :
                    null
                }
                {profileSelected === false?
                    <>
                        <div className='helloDate'>
                            <h3 className='date'>{currentDate}</h3>
                            <div className='horizontalDiv'>
                                <h1 className='hello'>Hi, {data.firstName}</h1>
                            </div>
                        </div>
                        <div className='content'>
                            <HeaderBlock />
                            <h1 className='rulesText'><i>*Select your short sleeve hoodie size (or gift card option) on your profile for prizing.*</i></h1>
                            <MedalBlock />
                            <TeamBlock />
                            <IndividualBlock />
                        </div>
                    </>
                    :
                    null
                }
            </div>
        </div>
        {isLoading === true?
            <Loader />
            :
            null
        }
    </div>
  );
};

export default Test;