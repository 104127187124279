import { collection, doc, getDoc, getDocs, limit, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../FirebaseAuthConfig.js';
import './secondRowBlock.css'

import Loader from '../Pages/loader.js';

function SecondRowBlock() {
    const [individualLeaderboard, setIndividualLeaderboard] = useState([]);
    const [teamLeaderboard, setTeamLeaderboard] = useState([]);
    const [teamSelected, setTeamSelected] = useState(false);

    const [teamData, setTeamData] = useState([]);
    const [memberData, setMemberData] = useState([]);

    const [fullIndividualLeaderboard, setFullIndividualLeaderboard] = useState([]);
    const [bigIndividualSelected, setBigIndividualSelected] = useState(false);
    const [fullTeamLeaderboard, setFullTeamLeaderboard] = useState([]);
    const [bigTeamSelected, setBigTeamSelected] = useState(false);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getIndividualLeaderboard = onSnapshot(query(collection(db, '/challengeCollection/immeasurablySore/individualLeaderboard/'), orderBy('points', 'desc'), limit(5)), (snapshot) => {
            setIndividualLeaderboard(snapshot.docs.map((col, index) => ({ ...col.data(), id: col.id, place: index+1 })));
        }, error => {
            console.log(error);
            setIndividualLeaderboard([]);
        });

        const getTeamLeaderboard = onSnapshot(query(collection(db, '/challengeCollection/immeasurablySore/teams/'), orderBy('points', 'desc'), limit(5)), (snapshot) => {
            setTeamLeaderboard(snapshot.docs.map((col, index) => ({ ...col.data(), id: col.id, place: index+1 })));
        }, error => {
            console.log(error);
            setTeamLeaderboard([]);
        });

        return () => {
            getIndividualLeaderboard();
            getTeamLeaderboard();
        }
    }, [])

    const english_ordinal_rules = new Intl.PluralRules('en', {type: 'ordinal'});
    const suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
    };

    function ordinal(number) {
        const catergory = english_ordinal_rules.select((number));
        const suffix = suffixes[catergory];
        return (number + suffix);
    }

    const getTeam = async (teamId) => {
        const teamRef = doc(db, `challengeCollection/immeasurablySore/teams/${teamId}`);
        const memberRef = query(collection(db, `challengeCollection/immeasurablySore/teams/${teamId}/members`), orderBy('points', 'desc'));

        await getDoc(teamRef)
        .then((result) => {
            setTeamData(result.data());
        });

        await getDocs(memberRef)
        .then((result) => {
            setMemberData(result.docs.map((col, index) => ({ ...col.data(), id: col.id })));
        });

        setIsLoading(false);
    };

    const getAllTeams = async () => {
        onSnapshot(query(collection(db, '/challengeCollection/immeasurablySore/teams/'), orderBy('points', 'desc')), (snapshot) => {
            setFullTeamLeaderboard(snapshot.docs.map((col, index) => ({ ...col.data(), id: col.id, place: index+1 })));
        }, error => {
            console.log(error);
            setFullTeamLeaderboard([]);
        });

        setIsLoading(false);
    };

    const getAllIndividuals = async () => {
        onSnapshot(query(collection(db, '/challengeCollection/immeasurablySore/individualLeaderboard/'), orderBy('points', 'desc')), (snapshot) => {
            setFullIndividualLeaderboard(snapshot.docs.map((col, index) => ({ ...col.data(), id: col.id, place: index+1 })));
        }, error => {
            console.log(error);
            setFullIndividualLeaderboard([]);
        });

        setIsLoading(false);
    };

    return(
        <>
            <div className='secondRow-block'>
                <div className='teamLeaderboard'>
                    <h2 className='sectionHeader'>Team Leaderboard</h2>
                    {teamLeaderboard.map((TDat) => {
                        return(
                            <>
                            {TDat.points > 0?
                                <button key={TDat.id} className='listBlock team' onClick={() => {
                                    setIsLoading(true);
                                    getTeam(TDat.id);
                                    setTeamSelected(true);
                                }}>
                                    <div className='section'>
                                        <div className='verticalText'>
                                            <h2 className='displayName'>{TDat.name}</h2>
                                            <h3 className='points'>{TDat.points === 1 ? `${TDat.points} Average Point` : `${TDat.points} Average Points`}</h3>
                                        </div>
                                    </div>
                                    {TDat.place === 1?
                                        <div className='placementBG first'>
                                            <h1 className='placementText'>{ordinal(TDat.place)}</h1>
                                        </div>
                                        :
                                        null
                                    }
                                    {TDat.place === 2?
                                        <div className='placementBG second'>
                                            <h1 className='placementText'>{ordinal(TDat.place)}</h1>
                                        </div>
                                        :
                                        null
                                    }
                                    {TDat.place === 3?
                                        <div className='placementBG third'>
                                            <h1 className='placementText'>{ordinal(TDat.place)}</h1>
                                        </div>
                                        :
                                        null
                                    }
                                    {TDat.place !== 1 && TDat.place !== 2 && TDat.place !== 3?
                                        <div className='placementBG'>
                                            <h1 className='placementText'>{ordinal(TDat.place)}</h1>
                                        </div>
                                        :
                                        null
                                    }
                                </button>
                                :
                                null
                            }
                            </>
                        )
                    })}
                    <button className='viewAllButton' onClick={() => {
                        setIsLoading(true);
                        getAllTeams();
                        setBigTeamSelected(true);
                    }}>View More</button>
                </div>
                <div className='individualLeaderboard'>
                    <h2 className='sectionHeader'>Individual Leaderboard</h2>
                    {individualLeaderboard.map((IDat) => {
                        return(
                            <div key={IDat.id} className='listBlock'>
                                <div className='section'>
                                    {IDat.profilePic !== ""?
                                        <img className='profilePic' referrerPolicy='no-referrer' src={IDat.profilePic} alt='profileImg'/>
                                        :
                                        null
                                    }
                                    {IDat.profilePic === ""?
                                        <div className='noProfilePic' />
                                        :
                                        null
                                    }
                                    <div className='verticalText'>
                                        <h2 className='displayName'>{IDat.displayName}</h2>
                                        <h3 className='points'>{IDat.points === 1 ? `${IDat.points} Point` : `${IDat.points} Points`}</h3>
                                    </div>
                                </div>
                                {IDat.place === 1?
                                    <div className='placementBG first'>
                                        <h1 className='placementText'>{ordinal(IDat.place)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {IDat.place === 2?
                                    <div className='placementBG second'>
                                        <h1 className='placementText'>{ordinal(IDat.place)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {IDat.place === 3?
                                    <div className='placementBG third'>
                                        <h1 className='placementText'>{ordinal(IDat.place)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {IDat.place !== 1 && IDat.place !== 2 && IDat.place !== 3?
                                    <div className='placementBG'>
                                        <h1 className='placementText'>{ordinal(IDat.place)}</h1>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        )
                    })}
                    <button className='viewAllButton' onClick={() => {
                        setIsLoading(true);
                        getAllIndividuals();
                        setBigIndividualSelected(true);
                    }}>View More</button>
                </div>
                <div className='resourcesContainer'>
                    <h2 className='sectionHeader'>Resources</h2>
                    <button className='resourceButton' onClick={() => window.open('https://immeasurablysore.com/rules', '_blank')}>
                        <div className='resourceImg' />
                        <h3 className='resourceText'>Challenge Rules</h3>
                    </button>
                    <button className='resourceButton' onClick={() => window.open('https://immeasurablysore.com/faq', '_blank')}>
                        <div className='resourceImg' />
                        <h3 className='resourceText'>FAQs</h3>
                    </button>
                    <button className='resourceButton' onClick={() => window.open('/scripture-memory', '_blank')}>
                        <div className='resourceImg' />
                        <h3 className='resourceText'>Scripture Memory</h3>
                    </button>
                    <button className='resourceButton' onClick={() => window.open('https://storage.googleapis.com/efit-ba871.appspot.com/assets/Website%20PDF.pdf', '_blank')}>
                        <div className='resourceImg' />
                        <h3 className='resourceText'>Bible Study</h3>
                    </button>
                    <button className='resourceButton' onClick={() => window.open('https://staff.elevationchurch.org/support/tickets/new', '_blank')}>
                        <div className='resourceImg' />
                        <h3 className='resourceText'>Technical Support</h3>
                    </button>
                    <button className='resourceButton bottom' onClick={() => window.open('https://immeasurablysore.com/prizing', '_blank')}>
                        <div className='resourceImg' />
                        <h3 className='resourceText'>Prizing</h3>
                    </button>
                </div>
            </div>
            {teamSelected === true?
                <div className='teamSelected-block'>
                    <div className='aniDiv'>
                        <div className='teamContainer'>
                            <button className='closeButton' onClick={() => {
                                setTeamSelected(false);
                                setTeamData([]);
                                setMemberData([]);
                            }} />
                            <h1 className='teamHeader'>{teamData.name}</h1>
                            <div className='teamInfoContainer'>
                                {memberData.map((MDat) => {
                                    return(
                                        <div key={MDat.id} className='teamEntry'>
                                            <div className='memberInfo'>
                                                <h2 className='memberName'>{MDat.displayName}</h2>
                                                <h2 className='memberRole'>{MDat?.role === 'captain' ? 'Captain' : ''}</h2>
                                            </div>
                                            <h2 className='memberPoints'>{MDat.points} Points</h2>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {bigIndividualSelected === true?
                <div className='teamSelected-block'>
                    <div className='aniDiv'>
                        <div className='teamContainer'>
                            <button className='closeButton' onClick={() => {
                                setBigIndividualSelected(false);
                                setFullIndividualLeaderboard([]);
                            }} />
                            <h1 className='teamHeader'>Individual Leaderboard</h1>
                            <div className='teamInfoContainer'>
                                {fullIndividualLeaderboard.map((BIDat) => {
                                    return(
                                        <div key={BIDat.id} className='teamEntry'>
                                            <div className='memberInfo'>
                                                <h2 className='memberName'>{BIDat.displayName}</h2>
                                            </div>
                                            <h2 className='memberPoints'>{BIDat.points} Points</h2>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {bigTeamSelected === true?
                <div className='teamSelected-block'>
                    <div className='aniDiv'>
                        <div className='teamContainer'>
                            <button className='closeButton' onClick={() => {
                                setBigTeamSelected(false);
                                setFullTeamLeaderboard([]);
                            }} />
                            <h1 className='teamHeader'>Team Leaderboard</h1>
                            <div className='teamInfoContainer'>
                                {fullTeamLeaderboard.map((BTDat) => {
                                    return(
                                        <div key={BTDat.id} className='teamEntry'>
                                            <div className='memberInfo'>
                                                <h2 className='memberName'>{BTDat.name}</h2>
                                            </div>
                                            <h2 className='memberPoints'>{BTDat.points} Average Points</h2>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {isLoading === true?
                <Loader />
                :
                null
            }
        </>
    )
}
export default SecondRowBlock;