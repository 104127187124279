import './memory.css';

import SRC_textImg from '../assets/TEXT.png';
import SRC_logo from '../assets/Logo/Logo@3x.png';

function Memory() {
    return(
        <div className='Memory'>
            <div className='mainDiv'>
                <img className='textImg' src={SRC_textImg} alt="textImg" />
                <h1 className='subHeader'>Scripture Memory</h1>

                <div className='memoryContainer'>
                    <h2 className='sectionHeader'>SCORING</h2>
                    <ul className='section'>
                        <li>1 Point for completing the first scripture option in the week.</li>
                        <li>1 Point for completing the second scripture option in the week.</li>
                        <p><i>*Must say your verse unassisted to someone from another team for credit, including the reference.*</i></p>
                    </ul>

                    <h2 className='sectionHeader'>WEEK 1</h2>
                    <ol className='section'>
                        <li>“<sup>20</sup>Now to him who is able to do immeasurably more than all we ask or imagine, according to his power that is at work within us,” - Ephesians 3:20</li>
                        <li>“<sup>20</sup>Now to him who is able to do immeasurably more than all we ask or imagine, according to his power that is at work within us, <sup>21</sup> to him be glory in the church and in Christ Jesus throughout all generations, for ever and ever! Amen.” - Ephesians 3:20-21</li>
                    </ol>

                    <h2 className='sectionHeader'>WEEK 2 </h2>
                    <ol className='section'>
                        <li>“<sup>15</sup>For this reason, ever since I heard about your faith in the Lord Jesus and your love for all God’s people, <sup>16</sup> I have not stopped giving thanks for you, remembering you in my prayers.” - Ephesians 1:15-16</li>
                        <li>“<sup>15</sup>For this reason, ever since I heard about your faith in the Lord Jesus and your love for all God’s people, <sup>16</sup> I have not stopped giving thanks for you, remembering you in my prayers. <sup>17</sup>I keep asking that the God of our Lord Jesus Christ, the glorious Father, may give you the Spirit of wisdom and revelation, so that you may know him better.” - Ephesians 1:15-17</li>
                    </ol>

                    <h2 className='sectionHeader'>WEEK 3 </h2>
                    <ol className='section'>
                        <li>“<sup>3</sup>All of us also lived among them at one time, gratifying the cravings of our flesh and following its desires and thoughts. Like the rest, we were by nature deserving of wrath.” - Ephesians 2:3</li>
                        <li>“<sup>3</sup>All of us also lived among them at one time, gratifying the cravings of our flesh and following its desires and thoughts. Like the rest, we were by nature deserving of wrath. <sup>4</sup>But because of his great love for us, God, who is rich in mercy, <sup>5</sup>made us alive with Christ even when we were dead in transgressions—it is by grace you have been saved.” - Ephesians 2:3-5</li>
                    </ol>

                    <h2 className='sectionHeader'>WEEK 4 </h2>
                    <ol className='section'>
                        <li>“<sup>11</sup>according to his eternal purpose that he accomplished in Christ Jesus our Lord. <sup>12</sup>“In him and through faith in him we may approach God with freedom and confidence.” - Ephesians 3:11-12</li>
                        <li>“<sup>11</sup>according to his eternal purpose that he accomplished in Christ Jesus our Lord. <sup>12</sup>“In him and through faith in him we may approach God with freedom and confidence.” <sup>13</sup>I ask you, therefore, not to be discouraged because of my sufferings for you, which are your glory. - Ephesians 3:11-13</li>
                    </ol>

                    <h2 className='sectionHeader'>WEEK 5 </h2>
                    <ol className='section'>
                        <li>“<sup>1</sup>As a prisoner for the Lord, then, I urge you to live a life worthy of the calling you have received. <sup>2</sup>Be completely humble and gentle; be patient, bearing with one another in love.” - Ephesians 4:1-2</li>
                        <li>“<sup>1</sup>As a prisoner for the Lord, then, I urge you to live a life worthy of the calling you have received. <sup>2</sup>Be completely humble and gentle; be patient, bearing with one another in love. <sup>3</sup>Make every effort to keep the unity of the Spirit through the bond of peace. <sup>4</sup>There is one body and one Spirit, just as you were called to one hope when you were called;” - Ephesians 4:1-4</li>
                    </ol>

                    <h2 className='sectionHeader'>WEEK 6 </h2>
                    <ol className='section'>
                        <li>“<sup>1</sup>Follow God’s example, therefore, as dearly loved children <sup>2</sup>and walk in the way of love, just as Christ loved us and gave himself up for us as a fragrant offering and sacrifice to God.” - Ephesians 5:1-2</li>
                        <li>“<sup>1</sup>Follow God’s example, therefore, as dearly loved children <sup>2</sup>and walk in the way of love, just as Christ loved us and gave himself up for us as a fragrant offering and sacrifice to God. <sup>3</sup>But among you there must not be even a hint of sexual immorality, or of any kind of impurity, or of greed, because these are improper for God’s holy people.” - Ephesians 5:1-3</li>
                    </ol>

                    <h2 className='sectionHeader'>WEEK 7 </h2>
                    <ol className='section'>
                        <li>“<sup>10</sup>Finally, be strong in the Lord and in his mighty power. <sup>11</sup>Put on the full armor of God, so that you can take your stand against the devil’s schemes.” - Ephesians 6:10-11</li>
                        <li>“<sup>10</sup>Finally, be strong in the Lord and in his mighty power. <sup>11</sup>Put on the full armor of God, so that you can take your stand against the devil’s schemes. <sup>12</sup>For our struggle is not against flesh and blood, but against the rulers, against the authorities, against the powers of this dark world and against the spiritual forces of evil in the heavenly realms.” - Ephesians 6:10-12</li>
                    </ol>
                </div>

                <img className='logoImg' src={SRC_logo} alt="logoImg" />
            </div>
        </div>
    )
}

export default Memory;