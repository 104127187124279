import { useEffect, useState } from 'react';
import { db, auth, functions } from '../FirebaseAuthConfig.js';
import { getDoc, doc, Timestamp, onSnapshot, getDocs, query, collection, orderBy } from 'firebase/firestore';
import { httpsCallable } from 'firebase/functions';
import './topRowBlock.css'

import SRC_strengthImg from '../assets/Icons/dumbbell.fill.png';
import SRC_eatImg from '../assets/Icons/fork.knife.png';
import SRC_restImg from '../assets/Icons/powersleep.png';
import SRC_offerImg from '../assets/Icons/book.png';

import SRC_strengthImgWhite from '../assets/Icons/dumbbell.fill(white).png';
import SRC_eatImgWhite from '../assets/Icons/fork.knife(white).png';
import SRC_restImgWhite from '../assets/Icons/powersleep(white).png';
import SRC_offerImgWhite from '../assets/Icons/book(white).png';

import Loader from '../Pages/loader.js';

function TopRowBlock() {
  const [currentAuthUser, setCurrentAuthUser] = useState(null);

  const [selectedDay, setSelectedDay] = useState('today');
  const [strengthGlobal, setStrengthGlobal] = useState(false);
  const [offerGlobal, setOfferGlobal] = useState(false);
  const [restGlobal, setRestGlobal] = useState(false);
  const [eatGlobal, setEatGlobal] = useState(false);
  const [mepsGlobal, setMepsGlobal] = useState(0);
  const [firstStrength, setFirstStrength] = useState(false);
  const [firstOffer, setFirstOffer] = useState(false);
  const [firstRest, setFirstRest] = useState(false);
  const [firstEat, setFirstEat] = useState(false);
  const [firstMeps, setFirstMeps] = useState(0);
  const [messageGlobal, setMessageGlobal] = useState('');
  const [remainingCharacters, setRemainingCharacters] = useState(0);
  const [alreadyLogged, setAlreadyLogged] = useState(false);
  const [record, setRecord] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const [isIndividual, setIsIndividual] = useState(false);
  const [strengthPoints, setStrengthPoints] = useState(0);
  const [offerPoints, setOfferPoints] = useState(0);
  const [restPoints, setRestPoints] = useState(0);
  const [eatPoints, setEatPoints] = useState(0);
  const [mepsPoints, setMepsPoints] = useState(0);

  const [teamName, setTeamName] = useState('');
  const [teamId, setTeamId] = useState('');
  const [teamSelected, setTeamSelected] = useState(false);
  const [teamData, setTeamData] = useState([]);
  const [memberData, setMemberData] = useState([]);

  const [isMessenger, setIsMessenger] = useState(false);

  const [disableToday, setDisableToday] = useState(false);

  setInterval(function() {
    var now = new Date().toLocaleTimeString('en-US', { hour:'2-digit', minute:'2-digit', second:'2-digit', hourCycle:'h12' });

    if (now === '12:01:00 AM') {
      window.location.reload();
    }
}, 1000);

  useEffect(() => {
    let unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
            setCurrentAuthUser(currentUser);
        }
    });
    return () => {
        unsubscribe()
    }
  }, []);

  useEffect(() => {
    if (!currentAuthUser) {
      return
    }

    const checkDay = getDate('today');
    if (checkDay === '2023-03-08') {
      setDisableToday(true);
      setSelectedDay('yesterday');
    }

    getProgressData();

  }, [currentAuthUser]);

  async function getProgressData() {
    const userPointsRef = doc(db, `userCollection/${currentAuthUser.uid}/challengeData/immeasurablySore/pointsPool/master`);
    const pointsSnap = await getDoc(userPointsRef);

    setStrengthPoints(pointsSnap.data()?.strengthPoints || 0);
    setOfferPoints(pointsSnap.data()?.offerPoints || 0);
    setRestPoints(pointsSnap.data()?.restPoints || 0);
    setEatPoints(pointsSnap.data()?.eatPoints || 0);
    setMepsPoints(pointsSnap.data()?.meps || 0);

    document.getElementById('individualProgressBar').style.width = `${((pointsSnap.data()?.points || 0)*100)/196}%`;

    const userRef = doc(db, `userCollection/${currentAuthUser.uid}/challengeData/immeasurablySore`);
    const userSnap = await getDoc(userRef);

    if (userSnap.exists) {
      if (userSnap.data()?.role === 'messenger') {
        setIsMessenger(true);
        console.log('Messenger');
      }
      if (userSnap.data().participationType === 'individual') {
        setIsIndividual(true);
      } else {
        setIsIndividual(false);
        const userTeam = userSnap.data().team;

        onSnapshot(doc(db, `challengeCollection/immeasurablySore/teams/${userTeam}`), (teamSnap) => {
          setTeamName(teamSnap.data()?.name || '');
          setTeamId(teamSnap.id);
          document.getElementById('teamProgressBar').style.width = `${((teamSnap.data()?.points || 0)*100)/196}%`;
        }, error => {
          console.log(error);
        });
      }
    }

    return;
  }

  useEffect(() => {
    if (!currentAuthUser) {
      return
    }

    getData();
    
  }, [selectedDay, currentAuthUser]);

  async function getData() {

    console.log("hello");

    const daySelection = document.getElementById('daySelection').value;
    const dateToGrab = getDate(daySelection);
    
    onSnapshot(doc(db, `userCollection/${currentAuthUser.uid}/challengeData/immeasurablySore/logData/${dateToGrab}`), (doc) => {
      setStrengthGlobal(doc.data()?.strength || false);
      setOfferGlobal(doc.data()?.offer || false);
      setRestGlobal(doc.data()?.rest || false);
      setEatGlobal(doc.data()?.eat || false);
      setMessageGlobal(doc.data()?.message || '');
      setMepsGlobal(doc.data()?.meps || 0);
      setAlreadyLogged(doc.data()?.logged || false);
      setRecord(doc.data()?.record || '');

      setFirstStrength(doc.data()?.strength || false);
      setFirstOffer(doc.data()?.offer || false);
      setFirstRest(doc.data()?.rest || false);
      setFirstEat(doc.data()?.eat || false);
      setFirstMeps(doc.data()?.meps || 0);

      return
    });
  };

  function getDate(day) {
    if (day === 'today') {
      const today = new Date();
      const stringify = today.toDateString();
      const date_obj = new Date(stringify);
      
      return `${date_obj.getFullYear()}-${date_obj.toLocaleString("default", { month: "2-digit" })}-${date_obj.toLocaleString("default", { day: "2-digit"})}`;
    } else if (day === 'yesterday') {
      const today = new Date();
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      const stringify = yesterday.toDateString();
      const date_obj = new Date(stringify);
      
      return `${date_obj.getFullYear()}-${date_obj.toLocaleString("default", { month: "2-digit" })}-${date_obj.toLocaleString("default", { day: "2-digit"})}`;
    };
  }

  const getTeam = async () => {
    const teamRef = doc(db, `challengeCollection/immeasurablySore/teams/${teamId}`);
    const memberRef = query(collection(db, `challengeCollection/immeasurablySore/teams/${teamId}/members`), orderBy('points', 'desc'));

    await getDoc(teamRef)
    .then((result) => {
        setTeamData(result.data());
    });

    await getDocs(memberRef)
    .then((result) => {
        setMemberData(result.docs.map((col, index) => ({ ...col.data(), id: col.id })));
    });

    setIsLoading(false);
  };

  return(
    <div className='topRow-block'>
      <div className='logContainer'>
        <div className='logSection'>
          <div className='headerArea'>
            <h2 className='logHeader'>Log Activity</h2>
            <select className='daySelection' id='daySelection' onChange={(e) => setSelectedDay(e.target.value)}>
              {disableToday === false?
                <option value='today'>Today</option>
                :
                null
              }
              <option value='yesterday'>Yesterday</option>
            </select>
          </div>
          <div className='logSection-elements'>
            <div className='elementButton'>
              <div className='elementDiv'>
                {strengthGlobal === false?
                  <button className='elementDot' onClick={() => setStrengthGlobal(true)}>
                    <img className='elementImg' src={SRC_strengthImg} alt="strength" />
                  </button>
                  :
                  null
                }
                {strengthGlobal === true?
                  <button className='elementDot selected' onClick={() => setStrengthGlobal(false)}>
                    <img className='elementImg' src={SRC_strengthImgWhite} alt="strength" />
                  </button>
                  :
                  null
                }
                <h4 className='elementHeader'>Strength</h4>
              </div>
            </div>

            <div className='elementButton'>
              <div className='elementDiv'>
              {offerGlobal === false?
                  <button className='elementDot' onClick={() => setOfferGlobal(true)}>
                    <img className='elementImg' src={SRC_offerImg} alt="offer" />
                  </button>
                  :
                  null
                }
                {offerGlobal === true?
                  <button className='elementDot selected' onClick={() => setOfferGlobal(false)}>
                  <img className='elementImg' src={SRC_offerImgWhite} alt="offer" />
                </button>
                  :
                  null
                }
                <h4 className='elementHeader'>Offer</h4>
              </div>
            </div>

            <div className='elementButton'>
              <div className='elementDiv'>
              {restGlobal === false?
                  <button className='elementDot' onClick={() => setRestGlobal(true)}>
                    <img className='elementImg' src={SRC_restImg} alt="rest" />
                  </button>
                  :
                  null
                }
                {restGlobal === true?
                  <button className='elementDot selected' onClick={() => setRestGlobal(false)}>
                  <img className='elementImg' src={SRC_restImgWhite} alt="rest" />
                </button>
                  :
                  null
                }
                <h4 className='elementHeader'>Rest</h4>
              </div>
            </div>

            <div className='elementButton'>
              <div className='elementDiv'>
              {eatGlobal === false?
                  <button className='elementDot' onClick={() => setEatGlobal(true)}>
                    <img className='elementImg' src={SRC_eatImg} alt="eat" />
                  </button>
                  :
                  null
                }
                {eatGlobal === true?
                  <button className='elementDot selected' onClick={() => setEatGlobal(false)}>
                  <img className='elementImg' src={SRC_eatImgWhite} alt="eat" />
                </button>
                  :
                  null
                }
                <h4 className='elementHeader'>Eat</h4>
              </div>
            </div>
          </div>
          <textarea maxLength={isMessenger === true ? '500' : '250'} className='messageInput' type='text' value={messageGlobal} placeholder={isMessenger === true ? 'Welcome, Message Maestro!' : 'Message (Optional)'} onChange={(e) => {
            setMessageGlobal(e.target.value);
            setRemainingCharacters(e.target.textLength);
          }} />
          <div className='extrasArea'>
            {strengthGlobal === true?
              <div className='mepsArea'>
                <h3 className='mepsHeader'>MEPS (if applicable): </h3>
                <input className='mepsInput' type='number' value={mepsGlobal} onChange={(e) => setMepsGlobal(e.target.value)} />
              </div>
              :
              null
            }
            <h6 className='remainingCharacters'>{isMessenger === true ? `${remainingCharacters}/500` : `${remainingCharacters}/250`}</h6>
          </div>
        </div>
        <button className='logButton' onClick={() => {
          if (strengthGlobal === false && offerGlobal === false && restGlobal === false && eatGlobal === false){
            alert('Please select a SORE Component in order to log.');
            return;
          }
          setIsLoading(true);

          const daySelection = document.getElementById('daySelection').value;
          const dateToGrab = getDate(daySelection);

          var data = {};

          var timeToPost = Date;

          if (daySelection === 'today') {
            const timestamp = Timestamp.now();
            const date = timestamp.toDate();
            timeToPost = date;
          } else {
            const timestamp = Timestamp.now();
            const date = timestamp.toDate();
            const yesterday = new Date(date);
            yesterday.setDate(yesterday.getDate() - 1);
            timeToPost = yesterday;
          }

          if (alreadyLogged) {
            data = {
              'strength': strengthGlobal,
              'offer': offerGlobal,
              'rest': restGlobal,
              'eat': eatGlobal,
              'meps': mepsGlobal,
              'message': messageGlobal,
              'date': dateToGrab,
              'logged': alreadyLogged,
              'record': record,
              'timestamp': timeToPost,
              'strengthTaken': firstStrength,
              'offerTaken': firstOffer,
              'restTaken': firstRest,
              'eatTaken': firstEat,
              'mepsTaken': firstMeps,
            };
          } else {
            data = {
              'strength': strengthGlobal,
              'offer': offerGlobal,
              'rest': restGlobal,
              'eat': eatGlobal,
              'meps': mepsGlobal,
              'message': messageGlobal,
              'date': dateToGrab,
              'logged': alreadyLogged,
              'timestamp': timeToPost
            };
          };

          const logData = httpsCallable(functions, 'logUserEntry');
          logData({ data })
          .then((result) => {
            const callback = result.data;
            console.log(callback);

            setIsLoading(false);

            if (callback.result !== "success") {
              alert('Error while logging data. Please try again later.');
            } else {
              getData();
              getProgressData();
            }
          });
        }}>
            <h2 className='logButton-header'>LOG</h2>
        </button>
      </div>
      <div className='progressContainer'>
        <div>
          <h2 className='section-header'>Individual Progress - {(strengthPoints + offerPoints + restPoints + eatPoints)}/196 Points</h2>
          <div className='progressBG'>
            <div className='progressIndividual' id='individualProgressBar' />
          </div>
        </div>
        
        {isIndividual === false?
          <div>
            <h2 className='section-header'>Team Progress - <a className='teamLink' onClick={() => {
                setIsLoading(true);
                getTeam();
                setTeamSelected(true);
              }}>{teamName}</a></h2>
            <div className='progressBG'>
              <div className='progressTeam' id='teamProgressBar' />
            </div>
          </div>
          :
          null
        }

        <div className='pointSection'>
          <h2 className='newHeader'>Points - 35 minimum required in each</h2>
          <div className='pointsList'>
            <div className='listItemVertical'>
              <h3 className='itemPoint'>{strengthPoints}</h3>
              <h3 className='itemHeader'>Strength</h3>
            </div>
            <div className='listItemVertical'>
              <h3 className='itemPoint'>{offerPoints}</h3>
              <h3 className='itemHeader'>Offer</h3>
            </div>
            <div className='listItemVertical'>
              <h3 className='itemPoint'>{restPoints}</h3>
              <h3 className='itemHeader'>Rest</h3>
            </div>
            <div className='listItemVertical'>
              <h3 className='itemPoint'>{eatPoints}</h3>
              <h3 className='itemHeader'>Eat</h3>
            </div>
            <div className='listItemVertical'>
              <h3 className='itemPoint'>{mepsPoints}</h3>
              <h3 className='itemHeader'>MEPs</h3>
            </div>
          </div>
        </div>
      </div>
      {isLoading === true?
        <Loader />
        :
        null
      }
      {teamSelected === true?
          <div className='teamSelected-block'>
              <div className='aniDiv'>
                  <div className='teamContainer'>
                      <button className='closeButton' onClick={() => {
                          setTeamSelected(false);
                          setTeamData([]);
                          setMemberData([]);
                      }} />
                      <h1 className='teamHeader'>{teamData.name}</h1>
                      <div className='teamInfoContainer'>
                          {memberData.map((MDat) => {
                              return(
                                  <div key={MDat.id} className='teamEntry'>
                                      <div className='memberInfo'>
                                          <h2 className='memberName'>{MDat.displayName}</h2>
                                          <h2 className='memberRole'>{MDat?.role === 'captain' ? 'Captain' : ''}</h2>
                                      </div>
                                      <h2 className='memberPoints'>{MDat.points} Points</h2>
                                  </div>
                              )
                          })}
                      </div>
                  </div>
              </div>
          </div>
          :
          null
      }
    </div>
  );
};

export default TopRowBlock;