import { useEffect, useState } from 'react';
import { db, auth } from '../FirebaseAuthConfig';
import { deleteField, doc, getDoc, updateDoc } from 'firebase/firestore';

import './profileBlock.css';
import Loader from '../Pages/loader.js';

import SRC_Chart from '../assets/chart.png';
import SRC_Hoodie from '../assets/hoodie.jpg';

function ProfileBlock() {
    const [isLoading, setIsLoading] = useState(true);
    const [secondLoader, setSecondLoader] = useState(false);

    const [userData, setUserData] = useState([]);
    const [displayName, setDisplayName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [participationType, setParticipationType] = useState('');
    const [teamCaptain, setTeamCaptain] = useState('');
    const [teamID, setTeamID] = useState('');
    const [teamName, setTeamName] = useState('');

    const [shirtSize, setShirtSize] = useState('');

    useEffect(() => {
        if (!auth.currentUser) {
            return;
        }

        const getUserData = async () => {
            const userRef = doc(db, `userCollection/${auth.currentUser.uid}`);
            const getUserData = await getDoc(userRef);
            setUserData(getUserData.data());

            setDisplayName(getUserData.data().displayName);
            setEmail(getUserData.data().email);
            setPhone(getUserData.data().phone);

            const challengeRef = doc(db, `userCollection/${auth.currentUser.uid}/challengeData/immeasurablySore`);
            const getChallengeData = await getDoc(challengeRef);
            setParticipationType(getChallengeData.data().participationType);
            setTeamCaptain(getChallengeData.data().teamCaptain);
            setTeamID(getChallengeData.data().team);
            setShirtSize(getChallengeData.data()?.shirtSize || '');

            if (getChallengeData.data().participationType !== 'individual') {
                const teamRef = doc(db, `challengeCollection/immeasurablySore/teams/${getChallengeData.data().team}`);
                const getTeamData = await getDoc(teamRef);
                setTeamName(getTeamData.data().name);
            }

            setIsLoading(false);
        }

        getUserData();

        return () => {
            //
        };
    }, []);

    const submitData = async (e) => {
        e.preventDefault();

        setSecondLoader(true);

        var userData = {}

        const userRef = doc(db, `userCollection/${auth.currentUser.uid}`);
        await updateDoc(userRef, {
            'displayName': displayName,
            'email': email,
            'phone': phone
        });

        if (participationType === 'teamMember') {
            userData = {
                'participationType': participationType,
                'teamCaptain': teamCaptain,
                'shirtSize': shirtSize,
            };
        } else {
            userData = {
                'participationType': participationType,
                'shirtSize': shirtSize,
                'teamCaptain': deleteField()
            };
        }
        
        const challengeRef = doc(db, `userCollection/${auth.currentUser.uid}/challengeData/immeasurablySore`);
        await updateDoc(challengeRef, userData);

        const registeredUserInChallengeColl = doc(db, `challengeCollection/immeasurablySore/registeredUsers/${auth.currentUser.uid}`);
        await updateDoc(registeredUserInChallengeColl, userData)

        if (participationType === 'teamCaptain') {
            const teamRef = doc(db, `challengeCollection/immeasurablySore/teams/${teamID}`);
            await updateDoc(teamRef, {
                'name': teamName,
            });
        };
        
        window.location.replace('/');

        secondLoader(false);
    }

    if (isLoading) {
        return(
            <Loader />
        )
    }

    return(
        <>
            <div className='profile-block'>
                <div className='container'>
                    <h2 className='header'>User Profile</h2>
                    <div className='mainDiv'>
                        <form className='infoForm' onSubmit={submitData}>
                            <label className='label'>First Name: <input className='input' type='text' disabled value={userData.firstName} /></label>
                            <label className='label'>Last Name: <input className='input' type='text' disabled value={userData.lastName} /></label>
                            <label className='label'>Display Name: <input className='input' type='text' value={displayName} required onChange={(e) => setDisplayName(e.target.value)} /></label>
                            {userData.authWithGoogle === 'true'?
                                <>
                                    <label className='label'>Email Address: <input className='input' type='text' disabled value={email} /></label>
                                    <label className='label'>Phone Number: <input className='input' type='text' value={phone} onChange={(e) => setPhone(e.target.value)} /></label>
                                </>
                                :
                                null
                            }
                            {userData.authWithGoogle === 'false'?
                                <>
                                    <label className='label'>Email Address: <input className='input' type='text' value={email} onChange={(e) => setEmail(e.target.value)} /></label>
                                    <label className='label'>Phone Number: <input className='input' type='text' disabled value={phone} /></label>
                                </>
                                :
                                null
                            }
                            <label className='label' id='label participationType'>Role:
                                <select className='input' id='input participationType' disabled value={participationType} onChange={(e) => setParticipationType(e.target.value)}>
                                    <option value='teamMember'>Member of my Department/Campus Team</option>
                                    <option value='teamCaptain'>Captain of my Department/Campus Team</option>
                                    <option value='individual'>Individual (I don't want to be on a team)</option>
                                </select>
                            </label>
                            {participationType === 'teamMember'?
                                <>
                                    <label className='label'>My team captain is: <i>*Please type N/A if unknown*</i><input className='input' type='text' disabled value={teamCaptain} onChange={(e) => setTeamCaptain(e.target.value)} /></label>
                                </>
                                :
                                null
                            }
                            {participationType === 'teamCaptain'?
                                <>
                                    <label className='label'>My team name is:<input className='input' type='text' value={teamName} onChange={(e) => setTeamName(e.target.value)} /></label>
                                </>
                                :
                                null
                            }
                            <div className='spacer' />
                            <label className='label' id='label shirtSize'>Short Sleeve Hoodie Size (OR $25 Elevation Merch Gift Card):
                                <select className='input' id='input shirtSize' value={shirtSize} onChange={(e) => setShirtSize(e.target.value)}>
                                    <option value=''>Please select a size (or gift card option)</option>
                                    <option value='XS'>XS</option>
                                    <option value='S'>S</option>
                                    <option value='M'>M</option>
                                    <option value='L'>L</option>
                                    <option value='XL'>XL</option>
                                    <option value='XXL'>XXL</option>
                                    <option value='XXXL'>XXXL</option>
                                    <option value='XXXXL'>XXXXL</option>
                                    <option value='giftCard'>$25 ELEVATION MERCH GIFT CARD</option>
                                </select>
                            </label>
                            <img className='sizingChartImg' src={SRC_Hoodie} alt='hoodie' />
                            <img className='sizingChartImg' src={SRC_Chart} alt='sizingChart' />
                            <button className='submitButton' type='submit'>Save & Update</button>
                        </form>
                    </div>
                </div>
            </div>
            <button className='profile-logOutButton' onClick={() => auth.signOut()}>Log Out</button>
        </>
    )
}

export default ProfileBlock;