import { collection, doc, getDoc, getDocs, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../FirebaseAuthConfig.js';
import './teamBlock.css'

import Loader from '../Pages/loader.js';

function TeamBlock() {
    const [teamLeaderboard, setTeamLeaderboard] = useState([]);
    const [teamSelected, setTeamSelected] = useState(false);

    const [teamData, setTeamData] = useState([]);
    const [memberData, setMemberData] = useState([]);

    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getTeamLeaderboard = onSnapshot(query(collection(db, '/challengeCollection/immeasurablySore/teams/'), orderBy('points', 'desc')), (snapshot) => {
            var tempArray = [];

            tempArray = snapshot.docs.map((col) => ({ ...col.data(), id: col.id, avgMEPS: (Math.round(col.data().meps/col.data().memberCount)), otherPoints: (Math.round(((col.data().totalPoints/col.data().memberCount) + 0.00001) * 100) / 100) }));

            tempArray.sort((a, b) => {
                if (a.points === b.points) {
                    return a.avgMEPS < b.avgMEPS ? 1 : -1
                } else {
                    return a.points < b.points ? 1 : -1
                }
            });
            setTeamLeaderboard(tempArray);
        }, error => {
            console.log(error);
            setTeamLeaderboard([]);
        });

        return () => {
            getTeamLeaderboard();
        }
    }, [])

    const english_ordinal_rules = new Intl.PluralRules('en', {type: 'ordinal'});
    const suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
    };

    function ordinal(number) {
        const catergory = english_ordinal_rules.select((number));
        const suffix = suffixes[catergory];
        return (number + suffix);
    }

    const getTeam = async (teamId) => {
        const teamRef = doc(db, `challengeCollection/immeasurablySore/teams/${teamId}`);
        const memberRef = query(collection(db, `challengeCollection/immeasurablySore/teams/${teamId}/members`), orderBy('points', 'desc'));

        await getDoc(teamRef)
        .then((result) => {
            setTeamData(result.data());
        });

        await getDocs(memberRef)
        .then((result) => {
            setMemberData(result.docs.map((col, index) => ({ ...col.data(), id: col.id })));
        });

        setIsLoading(false);
    };

    return(
        <>
            <div className='team-block'>
                <div className='teamLeaderboard'>
                    <h2 className='sectionHeader'>Team Leaderboard</h2>
                    {teamLeaderboard.map((TDat, index) => {
                        return(
                            <button key={TDat.id} className='listBlock team' onClick={() => {
                                setIsLoading(true);
                                getTeam(TDat.id);
                                setTeamSelected(true);
                            }}>
                                <div className='section'>
                                    <div className='verticalText'>
                                        <h2 className='displayName'>{TDat.name}</h2>
                                        <h3 className='pointsAndMeps'>{TDat.points} Average Points</h3>
                                        <h3 className='pointsAndMeps'>{TDat.avgMEPS} Average MEPs Per Person</h3>
                                    </div>
                                </div>
                                {index+1 === 1?
                                    <div className='placementBG first'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {index+1 === 2?
                                    <div className='placementBG second'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {index+1 === 3?
                                    <div className='placementBG third'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {index+1 !== 1 && index+1 !== 2 && index+1 !== 3?
                                    <div className='placementBG'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                            </button>
                        )
                    })}
                </div>
            </div>
            {teamSelected === true?
                <div className='teamSelected-block'>
                    <div className='aniDiv'>
                        <div className='teamContainer'>
                            <button className='closeButton' onClick={() => {
                                setTeamSelected(false);
                                setTeamData([]);
                                setMemberData([]);
                            }} />
                            <h1 className='teamHeader'>{teamData.name}</h1>
                            <div className='teamInfoContainer'>
                                {memberData.map((MDat) => {
                                    return(
                                        <div key={MDat.id} className='teamEntry'>
                                            <div className='memberInfo'>
                                                <h2 className='memberName'>{MDat.displayName}</h2>
                                                <h2 className='memberRole'>{MDat?.role === 'captain' ? 'Captain' : ''}</h2>
                                            </div>
                                            <h2 className='memberPoints'>{MDat.points} Points</h2>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
            {isLoading === true?
                <Loader />
                :
                null
            }
        </>
    )
}
export default TeamBlock;