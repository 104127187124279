import { useState, useEffect } from 'react';
import { db } from '../FirebaseAuthConfig.js';
import { collection, limit, onSnapshot, orderBy, query, doc, updateDoc, where, getDocs } from 'firebase/firestore';
import './activityBlock.css';

import SRC_strength from '../assets/Icons/dumbbell.fill.png';
import SRC_offer from '../assets/Icons/book.png';
import SRC_rest from '../assets/Icons/powersleep.png';
import SRC_eat from '../assets/Icons/fork.knife.png';

import Loader from '../Pages/loader.js';

function ActivityBlock() {
    const [data, setData] = useState([]);
    const [teamSelected, setTeamSelected] = useState(false);
    const [teamData, setTeamData] = useState([]);
    const [memberData, setMemberData] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    useEffect(() => {
        const getAllActivity = onSnapshot(query(collection(db, 'challengeCollection/immeasurablySore/allActivity'), orderBy('loggedAt', 'desc'), limit(100)), (snapshot) => {
            setData(snapshot.docs.map((col, index) => ({ ...col.data(), id: col.id })));
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        return () => {
            getAllActivity();
        };
    }, []);

    function addLike(chatId, currentLikes) {
        const entryRef = doc(db, `challengeCollection/immeasurablySore/allActivity/${chatId}`);
        const likeData = {
            'likes': currentLikes+1,
        };
        updateDoc(entryRef, likeData);
    }

    const getTeam = async (teamName) => {
        const teamRef = query(collection(db, `challengeCollection/immeasurablySore/teams`), where('name', '==', teamName));
    
        await getDocs(teamRef)
        .then(async (result) => {
            setTeamData(result.docs[0].data());

            const memberRef = query(collection(db, `challengeCollection/immeasurablySore/teams/${result.docs[0].id}/members`), orderBy('points', 'desc'));
            await getDocs(memberRef)
            .then((result) => {
                setMemberData(result.docs.map((col, index) => ({ ...col.data(), id: col.id })));
            });
        });
    
        setIsLoading(false);
      };

    if (data.length === 0) {
        return(
            <>
            </>
        )
    }

    return(
        <>
            <div className='activity-block'>
                <h1 className='activity-header'>All Activity</h1>
                <div className='activityContainer'>
                    {data.map((dat) => {
                        return(
                            <div key={dat.id} className='entry'>
                                <div className='entryTopRow'>
                                    <div className='userInfo'>
                                        <h2 className='displayName'>{dat.user}</h2>
                                        <h2 onClick={() => {
                                            setIsLoading(true);
                                            getTeam(dat.team);
                                            setTeamSelected(true);
                                        }} className='teamName'>{dat.team}</h2>
                                    </div>
                                    <h2 className='dateTime'>{dat.date}</h2>
                                </div>
                                {dat.message !== ''?
                                    <p className='entryMessage'>{dat.message}</p>
                                    :
                                    null    
                                }
                                <div className='entryBottomRow'>
                                    <div className='items'>
                                        {dat.strength === true?
                                            <img className='strength' src={SRC_strength} alt='strength' />
                                            :
                                            null
                                        }
                                        {dat.offer === true?
                                            <img className='offer' src={SRC_offer} alt='offer' />
                                            :
                                            null
                                        }
                                        {dat.rest === true?
                                            <img className='rest' src={SRC_rest} alt='rest' />
                                            :
                                            null
                                        }
                                        {dat.eat === true?
                                            <img className='eat' src={SRC_eat} alt='eat' />
                                            :
                                            null
                                        }
                                    </div>
                                    <div className='likeContainer'>
                                        <h3 className='likeAmount'>Likes: {dat.likes}</h3>
                                        <button className='likeButton' onClick={() => addLike(dat.id, dat.likes)}>
                                            <div className='likeImage' />
                                        </button>
                                    </div>
                                </div>
                            </div>
                        )
                    })}
                </div>
            </div>
            {isLoading === true?
                <Loader />
                :
                null
            }
            {teamSelected === true?
                <div className='teamSelected-block'>
                    <div className='aniDiv'>
                        <div className='teamContainer'>
                            <button className='closeButton' onClick={() => {
                                setTeamSelected(false);
                                setTeamData([]);
                                setMemberData([]);
                            }} />
                            <h1 className='teamHeader'>{teamData.name}</h1>
                            <div className='teamInfoContainer'>
                                {memberData.map((MDat) => {
                                    return(
                                        <div key={MDat.id} className='teamEntry'>
                                            <div className='memberInfo'>
                                                <h2 className='memberName'>{MDat.displayName}</h2>
                                                <h2 className='memberRole'>{MDat?.role === 'captain' ? 'Captain' : ''}</h2>
                                            </div>
                                            <h2 className='memberPoints'>{MDat.points} Points</h2>
                                        </div>
                                    )
                                })}
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
}

export default ActivityBlock;