import './prizes.css';

import SRC_textImg from '../assets/TEXT.png';
import SRC_logo from '../assets/Logo/Logo@3x.png';

function Prizes() {
    return(
        <div className='Prizes'>
            <div className='mainDiv'>
                <img className='textImg' src={SRC_textImg} alt="textImg" />
                <h1 className='subHeader'>Prizing</h1>

                <div className='PrizesContainer'>
                    <h2 className='sectionHeader'>Individual Prizes</h2>
                    <ul className='section'>
                        <li>Choice of branded short sleeve hoodie</li>
                        <p><i>*Must earn 140 total points minimum (35 points in each category) during the Challenge to receive a prize.*</i></p>
                    </ul>

                    <h2 className='sectionHeader'>Team Prizes</h2>
                    <ul className='section'>
                        <li>Nike or No Bull gift cards for top 5 teams</li>
                        <ul>
                            <li>First Place: $250</li>
                            <li>Second Place: $150</li>
                            <li>Third Place: $100</li>
                            <li>Fourth Place: $50</li>
                            <li>Fifth Place: $25</li>
                        </ul>
                        <p><i>*While all participants points count toward their team scores, completing the individual challenge is required to be eligible for their team prize.*</i></p>
                    </ul>
                </div>

                <img className='logoImg' src={SRC_logo} alt="logoImg" />
            </div>
        </div>
    )
}

export default Prizes;