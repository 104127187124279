import { collection, onSnapshot, orderBy, query } from 'firebase/firestore';
import { useEffect, useState } from 'react';
import { db } from '../FirebaseAuthConfig.js';
import './individualBlock.css'

function IndividualBlock() {
    const [individualLeaderboard, setIndividualLeaderboard] = useState([]);

    useEffect(() => {
        const getIndividualLeaderboard = onSnapshot(query(collection(db, '/challengeCollection/immeasurablySore/individualLeaderboard/'), orderBy('points', 'desc')), (snapshot) => {
            var tempArray = [];

            tempArray = snapshot.docs.map((col) => ({ ...col.data(), id: col.id }));

            tempArray.sort((a, b) => {
                if (a.points === b.points) {
                    return a.meps < b.meps ? 1 : -1
                } else {
                    return a.points < b.points ? 1 : -1
                }
            });
            setIndividualLeaderboard(tempArray);
        }, error => {
            console.log(error);
            setIndividualLeaderboard([]);
        });

        return () => {
            getIndividualLeaderboard();
        }
    }, [])

    const english_ordinal_rules = new Intl.PluralRules('en', {type: 'ordinal'});
    const suffixes = {
        one: 'st',
        two: 'nd',
        few: 'rd',
        other: 'th'
    };

    function ordinal(number) {
        const catergory = english_ordinal_rules.select((number));
        const suffix = suffixes[catergory];
        return (number + suffix);
    }

    return(
        <>
            <div className='individual-block'>
                <div className='individualLeaderboard'>
                    <h2 className='sectionHeader'>Individual Leaderboard</h2>
                    {individualLeaderboard.map((IDat, index) => {
                        return(
                            <div key={IDat.id} className='listBlock'>
                                <div className='section'>
                                    {IDat.profilePic !== ""?
                                        <img className='profilePic' referrerPolicy='no-referrer' src={IDat.profilePic} alt='profileImg'/>
                                        :
                                        null
                                    }
                                    {IDat.profilePic === ""?
                                        <div className='noProfilePic' />
                                        :
                                        null
                                    }
                                    <div className='verticalText'>
                                        <h2 className='displayName'>{IDat.displayName}</h2>
                                        <h3 className='pointsAndMeps'>{IDat.points === 1 ? `${IDat.points} Point` : `${IDat.points} Points`}</h3>
                                        <h3 className='pointsAndMeps'>{IDat.meps === 1 ? `${IDat.meps} MEP` : `${IDat.meps} MEPs`}</h3>
                                    </div>
                                </div>
                                {index+1 === 1?
                                    <div className='placementBG first'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {index+1 === 2?
                                    <div className='placementBG second'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {index+1 === 3?
                                    <div className='placementBG third'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                                {index+1 !== 1 && index+1 !== 2 && index+1 !== 3?
                                    <div className='placementBG'>
                                        <h1 className='placementText'>{ordinal(index+1)}</h1>
                                    </div>
                                    :
                                    null
                                }
                            </div>
                        )
                    })}
                </div>
            </div>
        </>
    )
}
export default IndividualBlock;