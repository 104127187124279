import './headerBlock.css';

import SRC_headerImg from '../assets/BKGRND.jpg';
import SRC_textImg from '../assets/TEXT.png';

function headerBlock() {
  return(
    <div className='header-block'>
      <img className='headerImg'src={SRC_headerImg} alt="headerImg" />
      <img className='textImg' src={SRC_textImg} alt="textImg" />
    </div>
  );
};

export default headerBlock;