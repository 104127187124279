import './faq.css';

import SRC_textImg from '../assets/TEXT.png';
import SRC_logo from '../assets/Logo/Logo@3x.png';

function Faq() {
    return(
        <div className='FAQ'>
            <div className='mainDiv'>
                <img className='textImg' src={SRC_textImg} alt="textImg" />
                <h1 className='subHeader'>Check out our IMMEASURABLY SORE FAQ's below but make sure you've read the <a href='https://immeasurablysore.com/rules'>RULES</a> first.</h1>

                <div className='faqContainer'>
                    <h2 className='sectionHeader'>How many team members are required?</h2>
                    <ul className='section'>
                        <li>10-12 members is ideal, but teams must have at least 10 members. Could be  more, but no less than 10.</li>
                        <li>Also leaderboard is based on an average per person.</li>
                    </ul>

                    <h2 className='sectionHeader'>How will points be tracked?</h2>
                    <ul className='section'>
                        <li>The fitness challenge site will track points with a 24 hour grace period for logging points.</li>
                        <p><i>*e.g. Individuals have until 11:59pm on Wednesday to enter your points earned on Tuesday before their points expire.*</i></p>
                    </ul>

                    <h2 className='sectionHeader'>When can I start logging?</h2>
                    <ul className='section'>
                        <li>The Challenge (and logging) will be opened Wednesday, January 18th @ 12:00am EST.</li>
                    </ul>

                    <h2 className='sectionHeader'>Can you explain the points/options available each day?</h2>
                    <ul className='section'>
                        <li><b>STRENGTH</b> (exercise): you choose from any of the 2 options each day and earn up to 1 point per day and up to 7 per week.</li>
                        <li><b>OFFER</b> (spiritual): there are 4 days of bible study each week in Immeasurably More which allows up to 4 points for the Bible Studies (1 per day). In addition to those 4 days, there will be 2 new scripture memory options each week (1 point each) as well as 1 for attending eGroup each week. That's up to 7 points per week for completing all 3 available options. </li>
                        <li><b>REST/RECOVERY</b>: you choose one of the 4 options each day and earn up to 1 point per day and up to 7 per week.</li>
                        <li><b>EAT</b> (nutrition): For the eating component, after the first week, you must commit to 1 plan for the remainder of the challenge and can earn up to 7 points each week. You can view the eating plan guide <a href='https://docs.google.com/document/d/1BoVYc_wAZrowSh5z7VXQZnEHaL65IZqAHv4EvY15K_w/edit?usp=sharing'>here</a>.</li>
                        <p></p>
                        <li>Here's how this could look over 5 days...</li>
                        <ul>
                            <li>Day 1 Sample Log: 4 points earned</li>
                            <ul>
                                <li>1 point for "40 minutes" from a strength workout (STRENGTH/EXERCISE)</li>
                                <li>1 point for "Immeasurably More Bible Study" (OFFER/SPIRITUAL)</li>
                                <li>1 point for "No devices 2 hours before bed" (REST/RECOVERY)</li>
                                <li>1 point for "No Sugar/No Grains" eating plan (EAT/NUTRITION)</li>
                            </ul>
                            <p></p>
                            <li>Day 2 Sample Log: 4 points earned</li>
                            <ul>
                                <li>1 point for "100 Myzone MEPS" from cardio workout (STRENGTH/EXERCISE)</li>
                                <li>1 point for first "Scripture Memory" (OFFER/SPIRITUAL)</li>
                                <li>1 point for "No Caffeine" (REST/RECOVERY)</li>
                                <li>1 point for "No Sugar/No Grains" eating plan (EAT/NUTRITION)</li>
                            </ul>
                            <p></p>
                            <li>Day 3 Sample Log: 4 points earned</li>
                            <ul>
                                <li>1 point for "115 Myzone MEPS" from strength and cardio workout (STRENGTH/EXERCISE)</li>
                                <li>1 point for "attending eGroup" (OFFER/SPIRITUAL)</li>
                                <li>1 point for "8 hours of sleep" (REST/RECOVERY)</li>
                                <li>1 point for "No Sugar/No Grains" eating plan (EAT/NUTRITION)</li>
                            </ul>
                            <p></p>
                            <li>Day 4 Sample Log: 4 points earned</li>
                            <ul>
                                <li>1 point for "120 Myzone MEPS" from high intensity workout (STRENGTH/EXERCISE)</li>
                                <li>1 point for second "Scripture Memory" (OFFER/SPIRITUAL)</li>
                                <li>1 point for "8 hours of sleep" (REST/RECOVERY)</li>
                                <li>1 point for "No Sugar/No Grains" eating plan (EAT/NUTRITION)</li>
                            </ul>
                            <p></p>
                            <li>Day 5 Sample Log: 3 points earned</li>
                            <ul>
                                <li>1 point for second "Immeasurably More Bible Study" (OFFER/SPIRITUAL)</li>
                                <li>1 point for "30 minutes of yoga/stretching" (REST/RECOVERY)</li>
                                <li>1 point for "No Sugar/No Grains" eating plan (EAT/NUTRITION)</li>
                            </ul>
                        </ul>
                    </ul>

                    <h2 className='sectionHeader'>Can I choose a different eating plan each day?</h2>
                    <ul className='section'>
                        <li>No you need consistency for long term success so changing everyday is not ideal nor allow you to maximize the benefits of an individual plan. You do have the option of trying out different plans the first week (something new/never offered in previous challenges) but after the first week, you will need to commit to one eating plan for the remainder of the challenge.</li>
                    </ul>

                    <h2 className='sectionHeader'>How do I change something on my registration or get help with getting registered?</h2>
                    <ul className='section'>
                        <li>For any technical issues, please submit a <a href='https://staff.elevationchurch.org/support/tickets/new'>tech ticket</a></li>
                    </ul>

                    <h2 className='sectionHeader'>I haven't recieved my Immeasurably More Bible Study Book. Will I still be able to earn those points?</h2>
                    <ul className='section'>
                        <li>Yes, Bible Studies will be available on this website until all books arrive and are distributed.</li>
                    </ul>

                    <h2 className='sectionHeader'>How can I change my team's name?</h2>
                    <ul className='section'>
                        <li>If you are the captain of your team, click on your profile picture in the top right on the home screen. There you should see a field called "Team Name" and be able to change your team's name to whatever you'd like.</li>
                    </ul>

                    <h2 className='sectionHeader'>What is Myzone?</h2>
                    <ul className='section'>
                        <li>It is a heart rate monitor platform we have as a tool to promote ongoing fitness and community. See more info <a href='https://www.youtube.com/watch?v=1QhrNN7s8yM'>here</a>.</li>
                    </ul>

                    <h2 className='sectionHeader'>Where can I get a Myzone Heart Rate Monitor?</h2>
                    <ul className='section'>
                        <li>Elevation Staff and Spouses can sign up for one <a href='https://docs.google.com/forms/d/e/1FAIpQLScDbkwa0OpYckQjcjPapfFdgkaXC7o9nkdL-1BsjtGgkP5ujw/viewform'>here</a>.</li>
                    </ul>

                    <h2 className='sectionHeader'>I'm having some issues - how can I get help with my Myzone HR Monitor?</h2>
                    <ul className='section'>
                        <li>Check out these troubleshooting <a href='https://myzonesupport.zendesk.com/hc/en-us/sections/360009690211-Troubleshooting'>support articles</a> first.</li>
                        <li>If that doesn't resolve the issue. Get free tech support in the app by submitting a ticket with Myzone here: MENU/HELP/SUPPORT/PROCEED</li>
                    </ul>
                </div>

                <img className='logoImg' src={SRC_logo} alt="logoImg" />
            </div>
        </div>
    )
}

export default Faq;