import './rules.css';

import SRC_textImg from '../assets/TEXT.png';
import SRC_logo from '../assets/Logo/Logo@3x.png';

function Rules() {
    return(
        <div className='Rules'>
            <div className='mainDiv'>
                <img className='textImg' src={SRC_textImg} alt="textImg" />
                <h1 className='subHeader'>A 2023 Elevation Staff Fitness Challenge</h1>

                <div className='rulesContainer'>
                    <h2 className='sectionHeader'>WHEN</h2>
                    <ul className='section'>
                        <li>Wednesday, January 18th to Tuesday, March 7th</li>
                        <li>Synced up with 7 weeks (49 Days) of Immeasurably More</li>
                        <li>Registration deadline is noon on Tuesday, 1/17.</li>
                        <p><i>*Logging will open at the start of the Challenge (Wednesday, January 18th @ 12:00am EST)*</i></p>
                    </ul>

                    <h2 className='sectionHeader'>TEAMS</h2>
                    <ul className='section'>
                        <li>Staff (& Staff Spouses) can sign up as a department/campus or as co-department/co-campus teams ranging from 10-12 members with a designated Team Captain.</li>
                        <p><i>*10-12 members is ideal, but teams must have at least 10 members. Could be a little more, but no less than 10.*</i></p>
                        <li>Teams have the option to release 1 team member at the 21 day mark (if they are not participating) but will not be eligible to win first or 2nd place if so. </li>
                        <li>Participants that do not want to be on a team will have the option to sign up as an individual.</li>
                    </ul>

                    <h2 className='sectionHeader'>FOUR COMPONENTS OF SORE</h2>
                    <ul className='section'>
                        <li><b>STRENGTH: 2 daily options</b></li>
                        <ul>
                            <li>100 Myzone MEPS (from an intentional workout)</li>
                            <li>40 minutes of challenging exercise</li>
                        </ul>
                        <li><b>OFFER (Romans 12:1 - I urge you…to OFFER your bodies as a living sacrifice):</b></li>
                        <ul>
                            <li>Immeasurably More Bible Study (4 days: 1 point per day up to 4 points per week)</li>
                            <li>eGroup attendance (1 point per week)</li>
                            <li>Weekly Scripture Memory (2 points per week)</li>
                            <ul>
                                <li>Must say your verse unassisted to someone from another team for credit</li>
                            </ul>
                            <p><i>*Week 1 Bible Studies will be available on this website until all books arrive and are distributed.*</i></p>
                        </ul>
                        <li><b>REST/RECOVERY: 4 daily options</b></li>
                        <ul>
                            <li>8+ hours of sleep</li>
                            <li>No devices 1-2 hours before bed</li>
                            <li>No caffeine</li>
                            <li>20 minutes of intentional stretching/flexibility/mobility work (this is separate and does not count for strength/exercise component. e.g. a yoga workout only counts for 1 component, not 2)</li>
                        </ul>
                        <li><b>EAT: 4 options to choose from</b></li>
                        <ul>
                            <li>No Added Sugar</li>
                            <li>No Grains</li>
                            <li>No Sugar & No Grains</li>
                            <li>800g of Fruit and Vegetables each day</li>
                            <p><i>*Participants have the option to try the different eating plans and make changes during the first week. Here is a guide for the different <a href='https://docs.google.com/document/d/1BoVYc_wAZrowSh5z7VXQZnEHaL65IZqAHv4EvY15K_w/edit?usp=sharing'>eating plans</a>.*</i></p>
                        </ul>
                    </ul>

                    <h2 className='sectionHeader'>POINTS</h2>
                    <ul className='section'>
                        <li><b>INDIVIDUAL GOAL</b></li>
                        <ul>
                            <li>Must earn 35 out of 49 points in each component to complete the challenge (an average of 5 out of 7 days).</li>
                            <li>140 total points minimum (35 required in each category) out of 196 total points to “complete" the challenge.</li>
                        </ul>
                        <li><b>TEAM GOAL</b></li>
                        <ul>
                            <li>The top 5 teams with the highest point total based on an average per person will win team prizes.</li>
                            <p><i>*Tiebreaker: Team with the highest average Myzone MEPS per person.*</i></p>
                        </ul>
                    </ul>

                    <h2 className='sectionHeader'>PRIZES</h2>
                    <ul className='section'>
                        <li><b>INDIVIDUAL COMPETITION</b></li>
                        <ul>
                            <li>Choice of branded short sleeve hoodie or shirt</li>
                            <p><i>*Must earn 140 total points minimum (35 points in each category) during the Challenge to receive a prize.*</i></p>
                        </ul>
                        <li><b>TEAM COMPETITION</b></li>
                        <ul>
                            <li>Nike or No Bull gift cards for top 5 teams</li>
                            <p><i>*While all participants points count toward their team scores, completing the individual challenge is required to be eligible for their team prize.*</i></p>
                        </ul>
                    </ul>

                    {/* <h2 className='sectionHeader'>APP</h2>
                    <ul className='section'>
                        <li>Logging will take place in the new eFit app with a 24 hour grace period for points.</li>
                        <ul>
                            <li>e.g. Individuals have until 11:59pm on Wednesday to enter your points earned on Tuesday before their points expire.</li>
                            <li>Point totals will be displayed to show remaining points needed for each component.</li>
                        </ul>
                    </ul> */}
                </div>
                <h2 className='questions'>GOT QUESTIONS? Contact Buck to schedule a team meeting. (<a href='mailto:bbuhler@elevationchurch.org'>bbuhler@elevationchurch.org</a>)</h2>
                <h2 className='questions'>NEED TECHNICAL SUPPORT? <a href='https://staff.elevationchurch.org/support/tickets/new'>Submit a ticket here.</a></h2>
                <h2 className='questions bottom'>Check out the Challenge FAQ <a href='https://immeasurablysore.com/faq'>HERE.</a></h2>

                <img className='logoImg' src={SRC_logo} alt="logoImg" />
            </div>
        </div>
    )
}

export default Rules;