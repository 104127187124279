import { Route, BrowserRouter as Router, Routes } from 'react-router-dom'

import Auth from './Auth/Auth.js';
import Home from './Pages/home.js';
import Register from './Pages/register.js';
import Rules from './Pages/rules.js';
import Faq from './Pages/faq.js';
import Prizes from './Pages/prizes.js';
import Memory from './Pages/memory';
import Admin from './Pages/admin.js';

import SignOut from './Pages/signOut.js';

import Test from './Pages/test.js';

// import Confetti from './Pages/confetti.js';

function routeFile() {
    return(
        <Router>
            <Routes>
                <Route path='/login' element={<Auth />} />\
                <Route path='/' element={<Home />} />
                <Route path='/home' element={<Home />} />
                <Route path='/register' element={<Register />} />
                <Route path='/rules' element={<Rules />} />
                <Route path='/faq' element={<Faq />} />
                <Route path='/scripture-memory' element={<Memory />} />
                <Route path='/prizing' element={<Prizes />} />
                <Route path='/admin' element={<Admin />} />
                <Route path='/sign-out' element={<SignOut />} />
                <Route path='/test' element={<Test />} />
                {/* <Route path='/confetti' element={<Confetti />} /> */}
            </Routes>
        </Router>
    );
}

export default routeFile;