import { useState, useEffect } from 'react';
import { auth, functions } from '../FirebaseAuthConfig';
import { RecaptchaVerifier, signInWithPhoneNumber, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';
import './Auth.css';

// import googleIcon from '../assets/googleIcon.png';
// import phoneIcon from '../assets/phone.fill.png';
import cancelIcon from '../assets/chevron.backward.png';
import challengeHeader from '../assets/TEXT.png';

import Loader from '../Pages/loader.js';

const provider = new GoogleAuthProvider();

function Auth() {
  const countryCode = '+1';

  const [phoneNumber, setPhoneNumber] = useState(countryCode);
  const [OTP, setOTP] = useState('');
  const [expandForm, setExpandForm] = useState(false);
  const [showPhone, setShowPhone] = useState(false);

  const [isLoading, setIsLoading] = useState(false);
  const [gError, setGError] = useState(false);
  const [currentAuthUser, setCurrentAuthUser] = useState(null);

  useEffect(() => {
    let unsubscribe = auth.onAuthStateChanged((currentUser) => {
        if (currentUser) {
            setCurrentAuthUser(currentUser);

            const checkUser = httpsCallable(functions, 'checkForUser');
            checkUser()
            .then((response) => {
              var resultData = response.data;

              if (resultData.result === 'userExists - ISregistered') {
                console.log('Found user');

                window.location.replace('/');

                return "Completed. Found Auth.";
              } else {
                return "Completed. No Auth discovered.";
              }
            });
        } else {
            return;
        }
    });
    return () => {
        unsubscribe()
    }
  }, []);

  const signInWithGoogle = () => {
    setIsLoading(true);
    setGError(false);

    signInWithPopup(auth, provider)
    .then((result) => {
        // const credential = GoogleAuthProvider.credentialFromResult(result);
        // const token = credential.accessToken;

        const user = result.user;
        var email = user.email;

        if (email.includes('@elevationchurch.org')) {
          console.log("Communicating with SF");
          salesForceCheck(true, user);
        } else if (email.includes('@elevationchurch.ca') || email.includes('@stevenfurtick.com')) {
          checkForUser(true, user, false);
        } else {
          setIsLoading(false);
          setGError(true);
        }

        // window.location.replace('http://localhost:3000/home');
    }).catch((error) => {
        const errorCode = error.code;
        const errorMessage = error.message;
        console.log(`Error Code: ${errorCode} | Error Message: ${errorMessage}`);

        setIsLoading(false);
        alert('Error: Please try again.');
    });
  };

  const generateRecaptcha = () => {
    window.recaptchaVerifier = new RecaptchaVerifier('recaptcha-container', {
      'size': 'invisible'
    }, auth);
  }

  const requestOTP = (e) => {
    e.preventDefault();

    var pn1 = phoneNumber.replace(/[^0-9]+/g, '')
    var pn2 = '+' + pn1
    setPhoneNumber(pn2);
    
    if (phoneNumber.length >= 12) {
      setExpandForm(true);
      generateRecaptcha();

      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  const secondRequestOTP = () => {
    if (phoneNumber.length >= 12) {
      let appVerifier = window.recaptchaVerifier;
      signInWithPhoneNumber(auth, phoneNumber, appVerifier)
      .then(confirmationResult => {
        window.confirmationResult = confirmationResult;
      })
      .catch((error) => {
        console.log(error);
      })
    }
  }

  const verifyOTP = (e) => {
    e.preventDefault();

    if (OTP.length === 6) {
      setIsLoading(true);

      let confirmationResult = window.confirmationResult;
      confirmationResult.confirm(OTP)
      .then((result) => {
        const user = result.user;
        console.log(user);
        console.log("Communicating with SF");

        salesForceCheck(false, user);
        
        // const newUserData = {
        //   'uid': user.uid,
        //   'displayName': 'Test Account',
        //   'email': 'testing@tester.com',
        //   'firstName': '',
        //   'lastName': '',
        //   'phoneNumber': phoneNumber,
        //   'photoURL': ''
        // };

        // checkForUser(false, newUserData, false);
      })
      .catch((error) => {
        console.log(error);
      });
    };
  }

  const salesForceCheck = (isGoogle, userData) => {
    const checkSF = httpsCallable(functions, 'getProfile');
    checkSF()
      .then((result) => {
        console.log(result.data);
        if (!result.data || result.data.exists === false || (!result.data.spouse && !result.data.staff)) {
          console.log('User does not exist in SF');

          setShowPhone(false);
          setExpandForm(false);
          setPhoneNumber('+1');
          setOTP('');
          setIsLoading(false);

          alert('Account Not Found. Please try again.');
        } else if (result.data.exists === true || result.data.exists === undefined) {
          let nonStaffSpouse = result.data.spouse && !result.data.staff;
          var newEmailAddress = result.data?.email || "";
          let newUserData = isGoogle ? userData : {
            'uid': userData.uid,
            'displayName': result.data.display_name,
            'email': newEmailAddress,
            'firstName': result.data.first_name,
            'lastName': result.data.last_name,
            'phoneNumber': phoneNumber,
            'photoURL': ''
          };
          checkForUser(isGoogle, newUserData, nonStaffSpouse);
        }
      });
  }

  async function checkForUser(isGoogle, userData, nonStaffSpouse) {
    var newEmailAddress = userData?.email || "";
    var newPhoneNumber = userData?.phoneNumber || "";
    var newProfilePic = userData?.photoURL || "";
    var newFirstName = ''
    var newLastName = '';
    var displayName = `${userData.displayName}`;
    var namePieces = []

    const checkUser = httpsCallable(functions, 'checkForUser');
    checkUser()
      .then((result) => {
        var resultData = result.data
        console.log(resultData);

        if (resultData.result === 'userExists - ISregistered') {
          console.log('Found user');

          window.location.replace('/');
        } else if (resultData.result === 'userExists - NOTregistered') {
          console.log('Found non-registered user');

          document.cookie = `non_staff_spouse=${nonStaffSpouse}`;
          window.location.replace('/register');
        } else if (resultData.result === 'noUserFound') {
          console.log('New user. Creating Account.')

          newEmailAddress = userData?.email || "";
          newPhoneNumber = userData?.phoneNumber || "";
          newProfilePic = userData?.photoURL || "";

          if (userData?.firstName === undefined || userData?.firstName === '') {
            namePieces = [];
            namePieces = displayName.split(" ");

            newFirstName = namePieces[0];
            newLastName = namePieces[1];
          } else {
            newFirstName = userData.firstName;
            newLastName = userData.lastName;
          }
          
          const newUserData = {
            'authWithGoogle': isGoogle,
            'displayName': userData.displayName,
            'email': newEmailAddress,
            'firstName': newFirstName,
            'lastName': newLastName,
            'phone': newPhoneNumber,
            'profilePic': newProfilePic
          };

          const createUser = httpsCallable(functions, 'createUserWithFB');
          createUser({ data: newUserData })
            .then((createResult) => {
              var callback = createResult.data
              console.log(callback);

              if (callback.result === "userCreated") {
                document.cookie = `non_staff_spouse=${nonStaffSpouse}`;
                window.location.replace('/register');
              } else {
                setShowPhone(false);
                setExpandForm(false);
                setPhoneNumber('+1');
                setOTP('');
                setIsLoading(false)

                alert('Error. Unable to register your account. Please try again later.');
              }
            })
            .catch((err) => {
              console.log(err);
            });
        } else if (resultData.result === 'userExistsInQuery') {
          // var path = `${resultData.path}`;
          // var userUID = path.slice(path.lastIndexOf('/')+1);

          // window.location.replace('http://localhost:3000/home');

          setShowPhone(false);
          setExpandForm(false);
          setPhoneNumber('+1');
          setOTP('');
          setIsLoading(false);

          alert("You already have an account. Please try a different sign in method.")
        } else {
          console.log('Error')
        }
      })
      .catch((error) => {
        console.log(error);
      });
  }

  const phoneButtonPressed = () => {
    setShowPhone(true);
  }

  const cancelButtonPressed = () => {
    setShowPhone(false);
    setExpandForm(false);
    setPhoneNumber('+1');
    setOTP('');
  }

  const openRules = () => {
    window.open('https://immeasurablysore.com/rules', '_blank')
  }

  const techTicket = () => {
    window.open('https://staff.elevationchurch.org/support/tickets/new', '_blank')
  }

  return(
    <div className="Auth">
      <div className='bgIMG' />
      <div className='mainDiv'>
        <div className="header">
            <h3 className="subtext">Welcome to</h3>
            <img className='headerImg' src={challengeHeader} alt='' />
            {/* <h1 className="headertext">eFit</h1> */}
        </div>

        {showPhone === true?
          <>
            {expandForm === false?
              <form onSubmit={requestOTP}>
                <div>
                  <h3 className='sentToText'>{'Please enter your Phone Number.'}</h3>
                  <input type='tel' className='inputText' id='phoneNumberInput' value={phoneNumber} placeholder='Phone Number' onChange={(e) => setPhoneNumber(e.target.value)} />
                  <div className='cancelAndSubmit'>
                    <button className='cancelButton' onClick={cancelButtonPressed}>
                      <img className='cancelImg' src={cancelIcon} alt='' />
                    </button>
                    <button className='submitButton' type='submit'>Get Code</button>
                  </div>
                </div>
              </form>
              :
              null
            }
            {expandForm === true?
              <form onSubmit={verifyOTP}>
                <div>
                  <h3 className='sentToText' id='sentToText'>{`Code sent to: (###) ###-${phoneNumber.substring(phoneNumber.length - 4)}`}</h3>
                  <input className='inputText' id='otpInput' value={OTP} placeholder='OTP Code' onChange={(e) => setOTP(e.target.value)} />
                  <div className='cancelAndSubmit'>
                    <button className='cancelButton' onClick={cancelButtonPressed}>
                      <img className='cancelImg' src={cancelIcon} alt='' />
                    </button>
                    <button className='submitButton' type='submit'>Verify and Register</button>
                  </div>
                  <button className='requestAgainButton' onClick={() => {
                    secondRequestOTP();
                    document.getElementById('sentToText').value = `Resent code to: (###) ###-${phoneNumber.substring(phoneNumber.length - 4)}`;
                  }}>
                    <h3 className='requestMain'>Didn't receive a code?</h3>
                    <h3 className='requestSub'>Request Again</h3>
                  </button>
                </div>
              </form>
              :
              null 
            }
            <div id='recaptcha-container' />
          </>
          :
          null
        }
        {showPhone === false?
          <div className='buttons'>
            <button className='button g' onClick={signInWithGoogle}>
              <div className='button base'>
                {/* <img className='google img' src={googleIcon} alt='' /> */}
                <h1 className='google text'>Sign In as Staff</h1>
              </div>
            </button>
            {gError === true?
              <h3 className='errorGText'>Please sign in with an Elevation Church Google account.</h3>
              :
              null
            }
            <button className='button p' onClick={phoneButtonPressed}>
              <div className='button base'>
                {/* <img className='phone img' src={phoneIcon} alt='' /> */}
                <h1 className='phone text'>Sign In as Staff Spouse</h1>
              </div>
            </button>
            <button className='button r' onClick={openRules}>
              <div className='button base'>
                <h2 className='rules text'>View The Challenge Rules</h2>
              </div>
            </button>
            <button className='button r' onClick={techTicket}>
              <div className='button base'>
                <h2 className='rules text'>Submit A Tech Ticket</h2>
              </div>
            </button>
          </div>
          :
          null
        }
      </div>
      {isLoading === true?
        <Loader />
        :
        null
      }
    </div>
  );
};

export default Auth;