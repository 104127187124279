import './loader.css';

function loader() {
    return(
        <div className='loaderDiv'>
            <div className='background' />
            <div className='wheel' />
        </div>
    )
}

export default loader;