import { useEffect } from 'react';
import { auth } from '../FirebaseAuthConfig.js';
import Loader from './loader.js';

function SignOut() {
    useEffect(() => {
        const logOut = async () => {
            await auth.signOut();
            window.location.replace('/login');
        };

        logOut();
    }, []);

    return(
        <Loader />
    )
}

export default SignOut;