import { useState, useEffect } from 'react';
import { collection, doc, getDoc, onSnapshot } from 'firebase/firestore';
import { db, auth, functions } from '../FirebaseAuthConfig';
import { httpsCallable } from 'firebase/functions';
import './medalBlock.css';

import SRC_lock from '../assets/Icons/lock.fill.png';

import SRC_strength from '../assets/gltf/strength.gltf';
import SRC_offer from '../assets/gltf/offer.gltf';
import SRC_rest from '../assets/gltf/rest.gltf';
import SRC_eat from '../assets/gltf/eat.gltf';
import SRC_week1 from '../assets/gltf/week1.gltf';
import SRC_week2 from '../assets/gltf/week2.gltf';
import SRC_week3 from '../assets/gltf/week3.gltf';
import SRC_week4 from '../assets/gltf/week4.gltf';
import SRC_week5 from '../assets/gltf/week5.gltf';
import SRC_week6 from '../assets/gltf/week6.gltf';
import SRC_week7 from '../assets/gltf/week7.gltf';
import SRC_complete from '../assets/gltf/complete.gltf';
import SRC_perfect from '../assets/gltf/perfect.gltf';

function MedalBlock() {
    const [currentAuthUser, setCurrentAuthUser] = useState(null);

    const [popped, setPopped] = useState(false);
    const [item, setItem] = useState('');
    const [disabled, setDisabled] = useState('');
    const [gold, setGold] = useState('');
    const [date, setDate] = useState('');
    const [displayName, setDisplayName] = useState('');
    const [title, setTitle] = useState('');
    const [description, setDescription] = useState('');

    const [strength, setStrength] = useState([]);
    const [offer, setOffer] = useState([]);
    const [rest, setRest] = useState([]);
    const [eat, setEat] = useState([]);
    const [week1, setWeek1] = useState([]);
    const [week2, setWeek2] = useState([]);
    const [week3, setWeek3] = useState([]);
    const [week4, setWeek4] = useState([]);
    const [week5, setWeek5] = useState([]);
    const [week6, setWeek6] = useState([]);
    const [week7, setWeek7] = useState([]);
    const [complete, setComplete] = useState([]);
    const [perfect, setPerfect] = useState([]);

    useEffect(() => {
        let unsubscribe = auth.onAuthStateChanged((currentUser) => {
            if (currentUser) {
                setCurrentAuthUser(currentUser);

                const checkUser = httpsCallable(functions, 'checkForUser');
                checkUser()
                .then((response) => {
                var resultData = response.data;

                if (resultData.result === 'userExists - ISregistered') {
                    return;
                } else {
                    auth.signOut();
                }
                });
            } else {
                console.log('No logged in user found. Redirecting.');

                window.location.replace('/login');
            }
        });
        return () => {
            unsubscribe()
        }
    }, []);

    useEffect(() => {
        if (!currentAuthUser) {
            return;
        }

        const getDisplayName = async () => {
            const userRef = doc(db, `userCollection/${currentAuthUser.uid}`);
            const userSnap = await getDoc(userRef);
            const name = userSnap.data()?.displayName || '';
            setDisplayName(name.toUpperCase());
        }

        const getMedalData = onSnapshot(collection(db, `userCollection/${currentAuthUser.uid}/challengeData/immeasurablySore/medals`), (snapshot) => {
            snapshot.docs.forEach((col, index) => {

                switch(col.id) {
                    case 'strength':
                        setStrength(col.data());
                        break;
                    case 'offer':
                        setOffer(col.data());
                        break;
                    case 'rest':
                        setRest(col.data());
                        break;
                    case 'eat':
                        setEat(col.data());
                        break;
                    case 'week1':
                        setWeek1(col.data());
                        break;
                    case 'week2':
                        setWeek2(col.data());
                        break;
                    case 'week3':
                        setWeek3(col.data());
                        break;
                    case 'week4':
                        setWeek4(col.data());
                        break;
                    case 'week5':
                        setWeek5(col.data());
                        break;
                    case 'week6':
                        setWeek6(col.data());
                        break;
                    case 'week7':
                        setWeek7(col.data());
                        break;
                    case 'complete':
                        setComplete(col.data());
                        break;
                    case 'perfect':
                        setPerfect(col.data());
                        break;
                    default:
                        console.log('hi');
                }
            });
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        getDisplayName();

        return () => {
            getMedalData();
        };
    }, [currentAuthUser]);

    function convertDate(dateGot) {
        const newDate = new Date(dateGot);
        let date, month, year;

        date = newDate.getDate();
        month = newDate.getMonth() + 1;
        year = newDate.getFullYear();

            date = date
                .toString()
                .padStart(2, '0');

            month = month
                .toString()
                .padStart(2, '0');

        return `${month}/${date}/${year}`;
    }

    return(
        <>
            <div className='medal-block'>
                <h1 className='medal-header'>Trophy Case</h1>
                <div className='medalContainer'>
                    {perfect.length !== 0?
                        <>
                            <button className={strength.dateUnlocked === '' ? 'medal strength disabled' : 'medal strength'} onClick={() => {
                                setPopped(true);
                                setItem('strength');
                                setGold('gold');
                                setTitle('Perfect Week (Strength)');

                                if (strength.dateUnlocked === '') {
                                    setDescription('Earn this award when you log STRENGTH every day of a single week.');

                                    setDisabled('disabled');
                                } else {
                                    if (strength.amount === 1) {
                                        setDescription(`You've earned this award ${strength.amount} time for logging STRENGTH every day of a single week. Most recent: ${convertDate(strength.dateUnlocked)}.`);
                                    } else {
                                        setDescription(`You've earned this award ${strength.amount} times for logging STRENGTH every day of a single week. Most recent: ${convertDate(strength.dateUnlocked)}.`);
                                    }

                                    setDisabled('');
                                    setDate(strength.dateUnlocked);
                                }
                            }}>
                                {strength.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={offer.dateUnlocked === '' ? 'medal offer disabled' : 'medal offer'} onClick={() => {
                                setPopped(true);
                                setItem('offer');
                                setGold('gold');
                                setTitle('Perfect Week (Offer)');

                                if (offer.dateUnlocked === '') {
                                    setDescription('Earn this award when you log OFFER every day of a single week.');

                                    setDisabled('disabled');
                                } else {
                                    if (offer.amount === 1) {
                                        setDescription(`You've earned this award ${offer.amount} time for logging OFFER every day of a single week. Most recent: ${convertDate(offer.dateUnlocked)}.`);
                                    } else {
                                        setDescription(`You've earned this award ${offer.amount} times for logging OFFER every day of a single week. Most recent: ${convertDate(offer.dateUnlocked)}.`);
                                    }

                                    setDisabled('');
                                    setDate(offer.dateUnlocked);
                                }
                            }}>
                                {offer.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={rest.dateUnlocked === '' ? 'medal rest disabled' : 'medal rest'} onClick={() => {
                                setPopped(true);
                                setItem('rest');
                                setGold('gold');
                                setTitle('Perfect Week (Rest)');

                                if (rest.dateUnlocked === '') {
                                    setDescription('Earn this award when you log REST every day of a single week.');

                                    setDisabled('disabled');
                                } else {
                                    if (rest.amount === 1) {
                                        setDescription(`You've earned this award ${rest.amount} time for logging REST every day of a single week. Most recent: ${convertDate(rest.dateUnlocked)}.`);
                                    } else {
                                        setDescription(`You've earned this award ${rest.amount} times for logging REST every day of a single week. Most recent: ${convertDate(rest.dateUnlocked)}.`);
                                    }

                                    setDisabled('');
                                    setDate(rest.dateUnlocked);
                                }
                            }}>
                                {rest.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={eat.dateUnlocked === '' ? 'medal eat disabled' : 'medal eat'} onClick={() => {
                                setPopped(true);
                                setItem('eat');
                                setGold('gold');
                                setTitle('Perfect Week (Eat)');

                                if (eat.dateUnlocked === '') {
                                    setDescription('Earn this award when you log EAT every day of a single week.');

                                    setDisabled('disabled');
                                } else {
                                    if (eat.amount === 1) {
                                        setDescription(`You've earned this award ${eat.amount} time for logging EAT every day of a single week. Most recent: ${convertDate(eat.dateUnlocked)}.`);
                                    } else {
                                        setDescription(`You've earned this award ${eat.amount} times for logging EAT every day of a single week. Most recent: ${convertDate(eat.dateUnlocked)}.`);
                                    }

                                    setDisabled('');
                                    setDate(eat.dateUnlocked);
                                }
                            }}>
                                {eat.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            
                            <button className={week1.dateUnlocked === '' ? 'medal week1 disabled' : 'medal week1'} onClick={() => {
                                setPopped(true);
                                setItem('week1');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week1.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 1.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 1. Unlocked: ${convertDate(week1.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(week1.dateUnlocked);
                                }
                            }}>
                                {week1.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={week2.dateUnlocked === '' ? 'medal week2 disabled' : 'medal week2'} onClick={() => {
                                setPopped(true);
                                setItem('week2');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week2.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 2.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 2. Unlocked: ${convertDate(week2.dateUnlocked)}.`);
                                    
                                    setDisabled('');
                                    setDate(week2.dateUnlocked);
                                }
                            }}>
                                {week2.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={week3.dateUnlocked === '' ? 'medal week3 disabled' : 'medal week3'} onClick={() => {
                                setPopped(true);
                                setItem('week3');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week3.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 3.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 3. Unlocked: ${convertDate(week3.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(week3.dateUnlocked);
                                }
                            }}>
                                {week3.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={week4.dateUnlocked === '' ? 'medal week4 disabled' : 'medal week4'} onClick={() => {
                                setPopped(true);
                                setItem('week4');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week4.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 4.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 4. Unlocked: ${convertDate(week4.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(week4.dateUnlocked);
                                }
                            }}>
                                {week4.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={week5.dateUnlocked === '' ? 'medal week5 disabled' : 'medal week5'} onClick={() => {
                                setPopped(true);
                                setItem('week5');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week5.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 5.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 5. Unlocked: ${convertDate(week5.dateUnlocked)}.`);
                                    
                                    setDisabled('');
                                    setDate(week5.dateUnlocked);
                                }
                            }}>
                                {week5.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={week6.dateUnlocked === '' ? 'medal week6 disabled' : 'medal week6'} onClick={() => {
                                setPopped(true);
                                setItem('week6');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week6.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 6.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 6. Unlocked: ${convertDate(week6.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(week6.dateUnlocked);
                                }
                            }}>
                                {week6.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={week7.dateUnlocked === '' ? 'medal week7 disabled' : 'medal week7'} onClick={() => {
                                setPopped(true);
                                setItem('week7');
                                setGold('');
                                setTitle('Perfect Week');

                                if (week7.dateUnlocked === '') {
                                    setDescription('Earn this award when you log all SORE components every day of WEEK 7.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging all SORE components every day of WEEK 7. Unlocked: ${convertDate(week7.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(week7.dateUnlocked);
                                }
                            }}>
                                {week7.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={complete.dateUnlocked === '' ? 'medal complete disabled' : 'medal complete'} onClick={() => {
                                setPopped(true);
                                setItem('complete');
                                setGold('gold');
                                setTitle('Challenge Complete')

                                if (complete.dateUnlocked === '') {
                                    setDescription('Earn this award when you log 35 points in each SORE component during the challenge.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging 35 points in each SORE component during the challenge. Unlocked: ${convertDate(complete.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(complete.dateUnlocked);
                                }
                            }}>
                                {complete.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                            <button className={perfect.dateUnlocked === '' ? 'medal perfect disabled' : 'medal perfect'} onClick={() => {
                                setPopped(true);
                                setItem('perfect');
                                setGold('perfect');
                                setTitle('Perfect Challenge')

                                if (perfect.dateUnlocked === '') {
                                    setDescription('Earn this award when you log the maximum points in each SORE component during the ENTIRE challenge.');

                                    setDisabled('disabled');
                                } else {
                                    setDescription(`You've earned this award for logging the maximum points in each SORE component during the ENTIRE challenge. Congratulations! Unlocked: ${convertDate(perfect.dateUnlocked)}.`);

                                    setDisabled('');
                                    setDate(perfect.dateUnlocked);
                                }
                            }}>
                                {perfect.dateUnlocked === ''?
                                    <img className='lockImg' src={SRC_lock} alt='SRC_lock' />
                                    :
                                    null
                                }
                            </button>
                        </>
                        :
                        null
                    }
                </div>
            </div>
            {popped === true?
                <div className='popupContainer'>
                    <div className='aniDiv'>
                        <div className='medalContainer'>
                            <button className='closeButton' onClick={() => setPopped(false)} />
                            <div className={`arScene ${disabled} ${gold}`}>
                            <a-scene embedded vr-mode-ui="enabled: false">
                                <a-sky opacity="0"></a-sky>
                                <a-entity scale="1.5 1.5 1.5" animation={disabled === 'disabled' ? '' : 'property: object3D.rotation.y; to: 360; dur: 7500; loop: true; easing: linear;'}>
                                    <a-gltf-model src={
                                        item === 'strength' ? SRC_strength : '' ||
                                        item === 'offer' ? SRC_offer : '' ||
                                        item === 'rest' ? SRC_rest : '' ||
                                        item === 'eat' ? SRC_eat : '' ||
                                        item === 'week1' ? SRC_week1 : '' ||
                                        item === 'week2' ? SRC_week2 : '' ||
                                        item === 'week3' ? SRC_week3 : '' ||
                                        item === 'week4' ? SRC_week4 : '' ||
                                        item === 'week5' ? SRC_week5 : '' ||
                                        item === 'week6' ? SRC_week6 : '' ||
                                        item === 'week7' ? SRC_week7 : '' ||
                                        item === 'complete' ? SRC_complete : '' ||
                                        item === 'perfect' ? SRC_perfect : ''
                                    } position="0 0.25 0" rotation="0 0 0"></a-gltf-model>
                                    {item === 'week1' || item === 'week2' || item === 'week3' || item === 'week4' || item === 'week5' || item === 'week6' || item === 'week7'?
                                        <>
                                            <a-text value={`EARNED BY ${displayName}\nON ${date.toUpperCase()}`} align="center" font='roboto' position="0 0.25 -0.04" rotation="0 180 0" opacity="0.25" scale="0.5 0.5 0.5" letterSpacing="0.5"></a-text>
                                            <a-text value="IMMEASURABLY SORE" align="center" font='roboto' position="0 -0.5 -0.04" rotation="0 180 0" opacity="0.25" scale="0.25 0.25 0.25" letterSpacing="0.5"></a-text>
                                        </>
                                        :
                                        null
                                    }
                                    {item === 'perfect'?
                                        <>
                                            <a-text value={`EARNED BY ${displayName}\nON ${date.toUpperCase()}`} align="center" font='roboto' position="0 0.25 -0.06" rotation="0 180 0" opacity="0.25" scale="0.5 0.5 0.5" letterSpacing="0.5"></a-text>
                                            <a-text value="IMMEASURABLY SORE" align="center" font='roboto' position="0 -0.5 -0.06" rotation="0 180 0" opacity="0.25" scale="0.25 0.25 0.25" letterSpacing="0.5"></a-text>
                                        </>
                                        :
                                        null
                                    }
                                </a-entity>
                                {gold === 'perfect'?
                                    <>
                                        <a-entity light="type: ambient; color: #FFFFFF; intensity: 1.5;"></a-entity>
                                    </>
                                    :
                                    null
                                }
                                {gold !== 'perfect'?
                                    <>
                                        <a-entity light="type: ambient; color: #FFFFFF;"></a-entity>
                                    </>
                                    :
                                    null
                                }
                                {gold === 'gold'?
                                    <>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 175;" position="0 5 1"></a-entity>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 125;" rotation="0 90 0" position="2 5 1"></a-entity>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 125;" rotation="0 -90 0" position="-2 5 1"></a-entity>
                                    </>
                                    :
                                    null
                                }
                                {gold === 'perfect'?
                                    <>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 150;" position="0 5 1"></a-entity>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 10;" rotation="0 90 0" position="2 5 1"></a-entity>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 10;" rotation="0 -90 0" position="-2 5 1"></a-entity>
                                    </>
                                    :
                                    null
                                }
                                {gold === ''?
                                    <>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 75;" position="0 5 1"></a-entity>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 25;" rotation="0 90 0" position="2 5 1"></a-entity>
                                        <a-entity light="type: point; color: #FFFFFF; intensity: 25;" rotation="0 -90 0" position="-2 5 1"></a-entity>
                                    </>
                                    :
                                    null
                                }
                                <a-camera look-controls-enabled="false" position="0 0.5 3"></a-camera>
                            </a-scene>
                            </div>
                            <div className='medalInfo'>
                                <h1 className='title'>{title}</h1>
                                <h2 className='description'>{description}</h2>
                            </div>
                        </div>
                    </div>
                </div>
                :
                null
            }
        </>
    )
}

export default MedalBlock;