import { useEffect, useState } from 'react';
import { db, auth } from '../FirebaseAuthConfig';
import { onSnapshot, collection, getDoc, doc } from 'firebase/firestore';
import './admin.css';

function Admin() {
    const [adminState, setAdminState] = useState(null);
    const [challengeData, setChallengeData] = useState({});
    const [userPoints, setUserPoints] = useState({});
    const [userData, setUserData] = useState([]);
    const [teams, setTeams] = useState([]);

    var number = 0;

    useEffect(() => {
        const unsubscribe = auth.onAuthStateChanged(async (currentUser) => {
            if (currentUser) {
                await getDoc(doc(db, `userCollection/${currentUser.uid}/challengeData/immeasurablySore`))
                .then((response) => {
                    if (response.data().isAdmin === true) {
                        setAdminState(true);
                    } else {
                        return window.location.replace('/login');
                    }
                })
            } else {
                return window.location.replace('/login');
            }
        });

        unsubscribe();

        return () => {
            unsubscribe()
        }
    }, []);

    useEffect(() => {
        if (!adminState) {
            return;
        }

        const getChallengeData = onSnapshot(collection(db, 'challengeCollection/immeasurablySore/registeredUsers'), (snapshot) => {
            console.log('getting data')
            var snapData = snapshot.docs.map((col) => ({id: col.id, ...col.data()})).reduce((result, collData) => {
                result[collData.id] = collData
                return result
            }, {})

            setChallengeData(snapData);
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        const getUserPoints = onSnapshot(collection(db, 'challengeCollection/immeasurablySore/individualLeaderboard'), (snapshot) => {
            var snapData = snapshot.docs.map((col) => ({id: col.id, ...col.data()})).reduce((result, collData) => {
                result[collData.id] = collData
                return result
            }, {})

            setUserPoints(snapData);
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        const getUserData = onSnapshot(collection(db, 'userCollection'), (snapshot) => {
            var snapData = snapshot.docs.map((result) => ({id: result.id, ...result.data()}));

            setUserData(snapData);
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        const getTeams = onSnapshot(collection(db, 'challengeCollection/immeasurablySore/teams'), (snapshot) => {
            var snapData = snapshot.docs.map((col) => ({id: col.id, ...col.data()}));

            setTeams(snapData);
        }, err => {
            console.log(`Encountered Error: ${err}`);
        });

        return () => {
            getChallengeData();
            getUserPoints();
            getUserData();
            getTeams();
        };
    }, [adminState]);

    return(
        <div className='Admin'>
            <h1>TEAMS</h1>
            <table>
                <tr>
                    <th>Team Name</th>
                    <th>Amount of Members</th>
                    <th>Average Points</th>
                    <th>MEPs</th>
                </tr>
                {teams.map((teamDat) => {
                    return(
                        <tr key={teamDat.id}>
                            <td>{teamDat.name}</td>
                            <td>{teamDat.memberCount}</td>
                            <td>{teamDat.points}</td>
                            <td>{teamDat.meps}</td>
                        </tr>
                    )
                })}
            </table>
            <h1></h1>
            <h1></h1>
            <h1></h1>
            <h1>USERS</h1>
            <table>
                <tr>
                    <th>User Number</th>
                    <th>Display Name</th>
                    <th>Email</th>
                    <th>Participation Type</th>
                    <th>Team Captain (if applicable)</th>
                    <th>Points</th>
                    <th>Short Sleeve Hoodie Size</th>
                </tr>
                {userData.filter(userDat => challengeData[userDat.id]).map((userDat) => {
                    var dat = challengeData[userDat.id];
                    var pDat = userPoints[userDat.id];
                    number = number + 1;
                    return(
                        <tr key={userDat.id}>
                            <td>{number}</td>
                            <td>{userDat.displayName}</td>
                            <td>{userDat.email}</td>
                            <td>{dat.participationType === 'individual' ? 'Individual' : '' || dat.participationType === 'teamMember' ? 'Team Member' : '' || dat.participationType === 'teamCaptain' ? 'Team Captain' : ''}</td>
                            <td>{dat.teamCaptain}</td>
                            <td>{pDat?.points || 0}</td>
                            <td>{dat?.shirtSize !== 'giftCard' ? dat?.shirtSize : 'Gift Card'}</td>
                        </tr>
                    )
                })}
            </table>
        </div>
    )
}

export default Admin;