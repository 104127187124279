import { initializeApp } from "firebase/app";
// import { getAnalytics } from "firebase/analytics";
import { getAuth, GoogleAuthProvider, signInWithPopup } from 'firebase/auth';
import { getFirestore } from '@firebase/firestore';
import { connectFunctionsEmulator, getFunctions } from "firebase/functions";

const firebaseConfig = {
  apiKey: "AIzaSyBFNMkhM9mGuTJIhm2BdLZv6wLDMY1mGcM",
  authDomain: "immeasurablysore.com",
  projectId: "efit-ba871",
  storageBucket: "efit-ba871.appspot.com",
  messagingSenderId: "916960279282",
  appId: "1:916960279282:web:31b43b47ba7d8f3a492e6b",
  measurementId: "G-281LTMQSC1"
};

const app = initializeApp(firebaseConfig);
// const analytics = getAnalytics(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, 'https://immeasurablysore.com');
export const db = getFirestore(app);

if (window.location.hostname === 'localhost') {
  connectFunctionsEmulator(functions, 'localhost', 5001)
}

const provider = new GoogleAuthProvider();

export const signInWithGoogle = () => {
  signInWithPopup(auth, provider).then((result) => {
    const displayName = result.user.displayName

    localStorage.setItem("displayName", displayName);
  }).catch((error) => {
    console.log(error);
  })
}